/* eslint-disable no-await-in-loop */
import React, {useEffect, useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal} from '@material-ui/core';
import {SplitPane} from 'react-multi-split-pane';

import * as axios from 'axios';
import {
  getTemplate,
  selectRegion,
  setRegionsChanged,
} from '../../slices/templateSlice';
import {
  getImageById,
  getImageRegionTypes,
  // getCountriesRequest,
  // getTemplatesForCountryRequest,
  // updatePageRegions,
} from '../../helpers/requester';

import {Cropper} from '../ImageCropper/ImageCropper';
import {Image} from '../RegionsPanel/Image';
import {RegionsPanel} from '../RegionsPanel/RegionsPanel';
import {ProccessDocPanel} from '../ProccessDocPanel/ProccessDocPanel';
import {AddRegion} from '../modals/AddRegionModal/AddRegion';
import {EditRegionAttrsModal} from '../modals/EditRegionAttrsModal/EditRegionAttrsModal';
import {ConfirmModal} from '../modals/ConfirmModal/ConfirmModal';
import styles from './Editor.module.css';

// async function pausecomp(millis) {
//   const date = new Date();
//   let curDate = null;
//   do {
//     curDate = new Date();
//   } while (curDate - date < millis);
//   return 0;
// }

// const nullToUndefined = obj => {
//   const res = Object.keys(obj).reduce((acc, key) => {
//     acc[key] = obj[key] === null ? undefined : obj[key];

//     return acc;
//   }, {});

//   return res;
// };

// const resetAllRegions = async () => {
//   const typesToChange = [3, 4, 13, 14, 16, 17, 18, 20, 21, 22];
//   const {data: defaultRegions} = await getImageRegionTypes();
//   const {data: countries} = await getCountriesRequest();

//   for (let i = 0; i < countries.length; i += 1) {
//     const countryId = countries[i].id;

//     const {data: templates} = await getTemplatesForCountryRequest(countryId);

//     for (let j = 0; j < templates.length; j += 1) {
//       const {front} = templates[j];
//       const {id: pageId, regions} = front;

//       if (regions.length > 0) {
//         const newReg = regions.map(({type, ...rest}) => {
//           if (typesToChange.includes(type.id)) {
//             const {format, id, importance, name, ...defaultAttrs} =
//               defaultRegions.find(reg => reg.id === type.id);

//             return nullToUndefined({...rest, ...defaultAttrs, type: type.name});
//           }

//           return nullToUndefined({...rest, type: type.name});
//         });

//         pausecomp(200);
//         // if (pageId === 1) {
//         const {isError} = await updatePageRegions({
//           oid: pageId,
//           page: {regions: newReg},
//         });
//         if (isError) {
//           console.log({pageId, regions: newReg}, countryId);
//         }
//         // }
//       }
//     }
//   }
// };

const regionsMarkupSplitSizes = JSON.parse(
  localStorage.getItem('regionsMarkupSplitSizes')
) || [6, 2];
const gridViewSplitSizes = JSON.parse(
  localStorage.getItem('gridViewSplitSizes')
) || [2, 5];

export function ImgEditorWrapper() {
  const dispatch = useDispatch();
  const {
    selectedPage: selectedPageKey,
    selectedRegion,
    selectedImageType,
    selectedTemplate,
    selectedImageId,
    regionsChanged,
    hideInvalidImages,
  } = useSelector(getTemplate);

  const [image, setImage] = useState('');
  const [newRegions, setNewRegions] = useState([]);
  const [addRegionModalOpen, setAddRegionModalOpen] = useState(false);
  const [removeRegionModalOpen, setRemoveRegionModalOpen] = useState(false);
  const [editRegionModalOpen, setEditRegionModalOpen] = useState('');
  const [status, setStatus] = useState('');
  const [isFetching, setIsFetching] = useState('');
  const [gridView, setGridView] = useState(false);

  const fetchImage = useCallback(
    async (id, cancelTokenSource) => {
      setIsFetching(id);
      const {data, status: newStatus} = await getImageById(
        id,
        selectedImageType,
        cancelTokenSource
      );
      setIsFetching(prevState => {
        return prevState && prevState === id ? false : prevState;
      });
      if (data) {
        const fr = new FileReader();
        fr.onload = () => {
          setImage(fr.result);
          setStatus(newStatus);
        };
        fr.readAsDataURL(data);
      } else {
        setStatus(422);
        setImage('');
      }
    },
    [selectedImageType]
  );

  const handleAddRegion = newRegion => {
    const editedRegions = [...newRegions, newRegion];
    setNewRegions(editedRegions);
    dispatch(selectRegion(newRegion));
    !regionsChanged && dispatch(setRegionsChanged(true));
  };

  const handleEditRegion = attrs => {
    const editedRegion = {...selectedRegion, ...attrs};

    const editedRegions = newRegions.map(region => {
      if (
        (region.id && region.id === selectedRegion?.id) ||
        (region.localId && region.localId === selectedRegion?.localId)
      ) {
        return editedRegion;
      }

      return region;
    });

    setNewRegions(editedRegions);
    dispatch(selectRegion(editedRegion));
    !regionsChanged && dispatch(setRegionsChanged(true));
  };

  const resetRegions = async () => {
    const {data: defaultRegions} = await getImageRegionTypes();

    const regions = newRegions.map(region => {
      const {format, id, importance, name, ...defaultAttrs} =
        defaultRegions.find(reg => reg.id === region.type.id);

      return {...region, ...defaultAttrs};
    });

    const newSelectedRegion = regions.find(
      region =>
        (region.id && region.id === selectedRegion?.id) ||
        (region.localId && region.localId === selectedRegion?.localId)
    );

    setNewRegions(regions);
    dispatch(selectRegion(newSelectedRegion || null));
    !regionsChanged && dispatch(setRegionsChanged(true));
  };

  const handleDeleteRegion = () => {
    const selectedRegionIndex = newRegions.findIndex(
      region =>
        (region.id && region.id === selectedRegion?.id) ||
        (region.localId && region.localId === selectedRegion?.localId)
    );

    if (selectedRegionIndex > -1) {
      const updatedRegions = [...newRegions];
      updatedRegions.splice(selectedRegionIndex, 1);

      setNewRegions(updatedRegions);
      dispatch(selectRegion(null));
      !regionsChanged && dispatch(setRegionsChanged(true));
    }
  };

  const onRegionChange = crop => {
    !regionsChanged && dispatch(setRegionsChanged(true));
    dispatch(selectRegion({...selectedRegion, ...crop}));
    setNewRegions(
      newRegions.map(region => {
        if (
          (region.id && region.id === selectedRegion?.id) ||
          (region.localId && region.localId === selectedRegion?.localId)
        ) {
          return {...region, ...crop};
        }
        return region;
      })
    );
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    selectedImageId
      ? fetchImage(selectedImageId, cancelTokenSource)
      : setImage('');

    return () => cancelTokenSource?.cancel();
  }, [fetchImage, selectedImageId]);

  useEffect(() => {
    const selectedPage = selectedTemplate?.[selectedPageKey];

    if (selectedPage?.regions) {
      const editedRegions = [...selectedPage.regions];
      setNewRegions(editedRegions);
    } else {
      setNewRegions([]);
    }

    dispatch(setRegionsChanged(false));
  }, [selectedTemplate, selectedPageKey, dispatch]);

  const images =
    (hideInvalidImages
      ? selectedTemplate?.[selectedPageKey]?.images.filter(
          ({document_visible}) => document_visible
        )
      : selectedTemplate?.[selectedPageKey]?.images) || [];

  return (
    <>
      <SplitPane
        split='vertical'
        defaultSizes={regionsMarkupSplitSizes}
        onDragFinished={size =>
          localStorage.setItem('regionsMarkupSplitSizes', JSON.stringify(size))
        }
      >
        <div className={styles.imageEditorWrapper}>
          <RegionsPanel
            removeRegion={() => {
              setRemoveRegionModalOpen(true);
            }}
            addRegion={() => {
              setAddRegionModalOpen(true);
            }}
            editRegion={() => {
              setEditRegionModalOpen(true);
            }}
            resetRegions={resetRegions}
            images={images}
            regions={newRegions || []}
            setGridView={setGridView}
          />
          {/* <buton onClick={resetAllRegions}>resetAllRegions</buton> */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              height: '100%',
            }}
          >
            {gridView ? (
              <SplitPane
                split='vertical'
                defaultSizes={gridViewSplitSizes}
                onDragFinished={size =>
                  localStorage.setItem(
                    'gridViewSplitSizes',
                    JSON.stringify(size)
                  )
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {images.map(img => (
                    <Image key={img.id} imageId={img.id} />
                  ))}
                </div>

                <Cropper
                  isFetching={isFetching}
                  status={status}
                  isImageValid={
                    selectedTemplate?.[selectedPageKey]?.images.find(
                      ({id}) => id === selectedImageId
                    )?.document_visible || false
                  }
                  key={selectedTemplate?.[selectedPageKey]?.id}
                  image={image}
                  regions={newRegions || []}
                  selectedRegion={newRegions.find(
                    ({id, localId}) =>
                      (id && id === selectedRegion?.id) ||
                      (localId && localId === selectedRegion?.localId)
                  )}
                  onRegionChange={onRegionChange}
                  editRegion={() => {
                    setEditRegionModalOpen(true);
                  }}
                  selectRegion={region => {
                    dispatch(selectRegion(region));
                  }}
                />
              </SplitPane>
            ) : (
              <Cropper
                isFetching={isFetching}
                status={status}
                isImageValid={
                  selectedTemplate?.[selectedPageKey]?.images.find(
                    ({id}) => id === selectedImageId
                  )?.document_visible || false
                }
                key={selectedTemplate?.[selectedPageKey]?.id}
                image={image}
                regions={newRegions || []}
                selectedRegion={newRegions.find(
                  ({id, localId}) =>
                    (id && id === selectedRegion?.id) ||
                    (localId && localId === selectedRegion?.localId)
                )}
                onRegionChange={onRegionChange}
                editRegion={() => {
                  setEditRegionModalOpen(true);
                }}
                selectRegion={region => {
                  dispatch(selectRegion(region));
                }}
              />
            )}
          </div>
        </div>
        <ProccessDocPanel image={image} />
      </SplitPane>
      <ConfirmModal
        open={removeRegionModalOpen}
        message='Are you sure you want to delete Region?'
        handleClose={() => {
          setRemoveRegionModalOpen(false);
        }}
        handleConfirm={handleDeleteRegion}
      />

      <Modal
        className={styles.modal}
        open={addRegionModalOpen}
        onClose={() => {
          setAddRegionModalOpen(false);
        }}
      >
        <AddRegion
          handleAddRegion={handleAddRegion}
          handleClose={() => {
            setAddRegionModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        className={styles.modal}
        open={editRegionModalOpen}
        onClose={() => {
          setEditRegionModalOpen(false);
        }}
      >
        <EditRegionAttrsModal
          handleEditRegion={handleEditRegion}
          handleClose={() => {
            setEditRegionModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}
