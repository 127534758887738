import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Tabs,
} from '@material-ui/core';
import {getTemplate, sortByName} from '../../../slices/templateSlice';
import {getCode, getLanguages} from '../../../helpers/requester';
import styles from './EditRegionAttrsModal.module.css';
import {EditRegionCodeModal} from '../EditRegionCodeModal/EditRegionCodeModal';

export function EditRegionAttrsModal({handleEditRegion, handleClose}) {
  const {selectedRegion, dictionaries} = useSelector(getTemplate);

  const [region, setRegion] = useState({});
  const [codeList, setCodeList] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [codeModalOpen, setCodeModalOpen] = useState(false);

  const getCodeAsync = async () => {
    const {data} = await getCode();

    setCodeList(data?.sort(sortByName) || []);
  };

  const getLanguagesAsync = async () => {
    const {data} = await getLanguages();

    setLanguages(data || []);
  };
  useEffect(() => {
    getCodeAsync();
    getLanguagesAsync();
  }, []);

  const dict = useMemo(() => {
    const {imgtype, ...data} = dictionaries;
    return data;
  }, [dictionaries]);

  useEffect(() => {
    if (selectedRegion) {
      setRegion({...selectedRegion});
    }
  }, [selectedRegion]);

  const handleUpdateRegions = () => {
    handleEditRegion(region);
    handleClose();
  };

  const removeCode = field => () => {
    setRegion({
      ...region,
      [field]: null,
    });
  };

  const converter = codeList.find(({id}) => id === region.converter);

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <div className='modal-title'>Edit region attrs</div>
        <div style={{display: 'flex', width: '100%', columnGap: '20px'}}>
          <FormControl className='DropDown' fullWidth variant='outlined'>
            <InputLabel id='Select ocralgo'>Select ocralgo</InputLabel>
            <Select
              labelId='Select ocralgo'
              label='Select ocralgo'
              fullWidth
              value={region?.ocralgo || ''}
              onChange={e => {
                setRegion({
                  ...region,
                  ocralgo: e.target.value,
                });
              }}
            >
              {dict.ocralgo.map(([value, dictName]) => (
                <MenuItem key={value} value={value}>
                  {dictName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className='DropDown' fullWidth variant='outlined'>
            <InputLabel id='Select ocralgo'>Select language</InputLabel>
            <Select
              labelId='Select language'
              label='Select language'
              fullWidth
              value={region?.lang || ''}
              onChange={e => {
                setRegion({
                  ...region,
                  lang: e.target.value,
                });
              }}
            >
              {languages.map(({name, code}) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{width: '100%', display: 'flex', whiteSpace: 'nowrap'}}>
          <FormControlLabel
            checked={region?.nonempty || false}
            control={
              <Checkbox
                onChange={e => {
                  setRegion({
                    ...region,
                    nonempty: e.target.checked,
                  });
                }}
              />
            }
            label='Non empty'
          />
          <FormControlLabel
            checked={region?.critverify || false}
            control={
              <Checkbox
                onChange={e => {
                  setRegion({
                    ...region,
                    critverify: e.target.checked,
                  });
                }}
              />
            }
            label='Crit verify'
          />
          <FormControlLabel
            checked={region?.lowres || false}
            control={
              <Checkbox
                onChange={e => {
                  setRegion({
                    ...region,
                    lowres: e.target.checked,
                  });
                }}
              />
            }
            label='Lowres'
          />

          <FormControl
            className='DropDown'
            fullWidth
            style={{width: 'calc(50% - 10px)', marginLeft: 'auto'}}
            variant='outlined'
          >
            <InputLabel id='Select ocralgo'>Select Isolate</InputLabel>
            <Select
              labelId='Select Isolate'
              label='Select Isolate'
              fullWidth
              value={region?.Isolate || ''}
              onChange={e => {
                setRegion({
                  ...region,
                  Isolate: e.target.value,
                });
              }}
            >
              {dictionaries.isolate.map(([value, dictName]) => (
                <MenuItem key={value} value={value}>
                  {dictName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='nav-bar'>
          <Tabs
            value={selectedTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(e, val) => {
              setSelectedTab(val);
            }}
          >
            <Tab label='Verify' />
            <Tab label='Filter' />
          </Tabs>
        </div>

        {selectedTab === 0 &&
          [1, 2, 3, 4, 5].map(num => {
            const code = codeList.find(({id}) => id === region[`verify${num}`]);

            return (
              <div className={styles.codeRow}>
                <a href={`/code?code=${code?.id}`} target='blank'>
                  {code?.name}
                  {code?.id && <>({code?.id})</>}
                  <div>{code?.desk}</div>
                </a>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setCodeModalOpen({
                      fieldName: `verify${num}`,
                      fieldValue: region[`verify${num}`],
                      type: 1,
                    });
                  }}
                  type='button'
                >
                  Edit
                </Button>
                <Button
                  disabled={!region[`verify${num}`]}
                  onClick={removeCode(`verify${num}`)}
                  variant='outlined'
                  type='button'
                >
                  Delete
                </Button>
              </div>
            );
          })}

        {selectedTab === 1 &&
          [1, 2, 3, 4, 5].map(num => {
            const code = codeList.find(({id}) => id === region[`filter${num}`]);

            return (
              <div className={styles.codeRow}>
                <a href={`/code?code=${code?.id}`} target='blank'>
                  {code?.name}
                  <div>{code?.desk}</div>
                </a>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setCodeModalOpen({
                      fieldName: `filter${num}`,
                      fieldValue: region[`filter${num}`],
                      type: 2,
                    });
                  }}
                  type='button'
                >
                  Edit
                </Button>
                <Button
                  disabled={!region[`filter${num}`]}
                  variant='outlined'
                  onClick={removeCode(`filter${num}`)}
                  type='button'
                >
                  Delete
                </Button>
              </div>
            );
          })}
        <div>Converter</div>
        <div>
          <div className={styles.codeRow}>
            <a href={`/code?code=${converter?.id}`} target='blank'>
              {converter?.name}
              <div>{converter?.desk}</div>
            </a>
            <Button
              variant='outlined'
              onClick={() => {
                setCodeModalOpen({
                  fieldName: `converter`,
                  fieldValue: region.converter,
                  type: 3,
                });
              }}
              type='button'
            >
              Edit
            </Button>
            <Button
              disabled={!region.converter}
              onClick={removeCode(`converter`)}
              variant='outlined'
              type='button'
            >
              Delete
            </Button>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button variant='contained' onClick={handleUpdateRegions}>
            Update Region
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>

      <Modal
        className={styles.modal}
        open={codeModalOpen}
        onClose={() => {
          setCodeModalOpen(false);
        }}
      >
        <EditRegionCodeModal
          getCodeAsync={getCodeAsync}
          handleClose={() => {
            setCodeModalOpen(false);
          }}
          handleEditRegion={field => {
            setRegion({...region, ...field});
          }}
          codeList={codeList.filter(({type}) => {
            return type === codeModalOpen.type;
          })}
          {...codeModalOpen}
        />
      </Modal>
    </div>
  );
}
