import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from '@material-ui/core';
import {
  AddCircleOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons';

import {
  loadDocumentTypes,
  loadTemplatesForCountry,
  getTemplate,
  loadCountries,
  selectPage,
  selectTemplate,
  loadDictionaries,
  selectCountry,
} from '../../slices/templateSlice';
import {deleteTemplate} from '../../helpers/requester';

import {AddTemplate} from '../modals/AddTemplateModal/AddTemplate';
import {EditPage} from '../modals/EditPageModal/EditPage';
import {ConfirmModal} from '../modals/ConfirmModal/ConfirmModal';
import styles from './TemplatesPanel.module.css';

const pages = ['front', 'back'];

export function TemplatesPanel() {
  const {
    countries,
    templatesForCountry,
    selectedTemplate,
    selectedPage,
    selectedCountry,
    regionsChanged,
  } = useSelector(getTemplate);
  const dispatch = useDispatch();
  const [addModalOpen, setAddModalOpen] = useState('');
  const [editPageModalOpen, setEditPageModalOpen] = useState('');
  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadCountries());
    dispatch(loadDocumentTypes());
    dispatch(loadDictionaries());
  }, [dispatch]);

  useEffect(() => {
    selectedCountry && dispatch(loadTemplatesForCountry(selectedCountry));
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(selectPage(selectedPage || 'front'));
    } else {
      dispatch(selectPage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTemplate]);

  const handleDeleteTemplate = async () => {
    const {isError} = await deleteTemplate(selectedTemplate?.id);

    if (!isError) {
      selectTemplate(null);
      toast.warn('Template deleted');
      selectedCountry &&
        (await dispatch(loadTemplatesForCountry(selectedCountry)));
    }
  };

  const handleSelectCountry = e => {
    regionsChanged
      ? setConfirmModalOpen({
          open: true,
          handleConfirm: () => {
            dispatch(selectCountry(e.target.value));
          },
        })
      : dispatch(selectCountry(e.target.value));
  };

  const handleSelectTemplate = e => {
    const {value} = e.target;
    const fullTemplate = templatesForCountry.find(({id}) => id === value) || '';

    regionsChanged
      ? setConfirmModalOpen({
          open: true,
          handleConfirm: () => {
            dispatch(selectTemplate(fullTemplate));
          },
        })
      : dispatch(selectTemplate(fullTemplate));
  };

  const handleSelectPage = e => {
    const {value} = e.target;

    regionsChanged
      ? setConfirmModalOpen({
          open: true,
          handleConfirm: () => {
            dispatch(selectPage(value));
          },
        })
      : dispatch(selectPage(value));
  };

  return (
    <div className={styles.selectionWrapper}>
      <div className='group-wrapper sm'>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>Select country</InputLabel>
          <Select
            labelId='select-country-label'
            label='Select country'
            value={selectedCountry}
            onChange={handleSelectCountry}
          >
            {countries.map(({id, name}) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className='group-wrapper lg'>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>Select template</InputLabel>
          <Select
            labelId='select-country-label'
            label='Select template'
            disabled={!selectedCountry}
            value={selectedTemplate?.id || ''}
            onChange={handleSelectTemplate}
          >
            {templatesForCountry.map(({id, name}) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.buttonsWrapper}>
          <Button
            title='Add template'
            variant='outlined'
            onClick={() => {
              setAddModalOpen('add');
            }}
          >
            <AddCircleOutlined />
          </Button>
          <Button
            title='Edit template'
            variant='outlined'
            disabled={!selectedTemplate}
            onClick={() => {
              setAddModalOpen('edit');
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            title='Delete template'
            variant='outlined'
            disabled={!selectedTemplate}
            onClick={() => {
              setDeleteTemplateModalOpen(true);
            }}
          >
            <DeleteForeverOutlined />
          </Button>
        </div>
      </div>
      <div className='group-wrapper'>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>Select page</InputLabel>
          <Select
            labelId='select-country-label'
            fullWidth
            label='Select page'
            disabled={!selectedTemplate}
            value={selectedPage}
            onChange={handleSelectPage}
          >
            {pages.map(value => (
              <MenuItem
                key={value}
                disabled={!selectedTemplate?.[value]}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.buttonsWrapper}>
          <Button
            title='Edit page'
            variant='outlined'
            disabled={!selectedPage}
            onClick={() => {
              setEditPageModalOpen(true);
            }}
          >
            <EditOutlined />
          </Button>
        </div>
      </div>

      <Modal
        className={styles.modal}
        open={addModalOpen}
        onClose={() => {
          setAddModalOpen('');
        }}
      >
        <AddTemplate
          handleClose={() => {
            setAddModalOpen('');
          }}
          template={addModalOpen === 'edit' ? selectedTemplate : undefined}
        />
      </Modal>
      <Modal
        className={styles.modal}
        open={editPageModalOpen}
        onClose={() => {
          setEditPageModalOpen(false);
        }}
      >
        <EditPage
          handleClose={() => {
            setEditPageModalOpen(false);
          }}
        />
      </Modal>

      <ConfirmModal
        open={deleteTemplateModalOpen}
        message='Are you sure you want to delete Template?'
        extraConfirmMsg='Confirm TEMPLATE deletion'
        handleClose={() => {
          setDeleteTemplateModalOpen(false);
        }}
        handleConfirm={handleDeleteTemplate}
      />
      <ConfirmModal
        open={confirmModalOpen?.open}
        message='You have unsaved changes. Are you sure you want to continue?'
        handleClose={() => {
          setConfirmModalOpen(false);
        }}
        handleConfirm={confirmModalOpen?.handleConfirm}
      />
    </div>
  );
}
