import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import * as axios from 'axios';
import {getImageById} from '../../helpers/requester';
import {getTemplate, selectImage} from '../../slices/templateSlice';

import styles from './RegionsPanel.module.css';

export const Image = ({imageId}) => {
  const dispatch = useDispatch();
  const {selectedImageType, selectedImageId} = useSelector(getTemplate);
  const [image, setImage] = useState('');
  const [statusCode, setSatusCode] = useState('');

  const fetchImage = useCallback(
    async (id, cancelTokenSource) => {
      const {status, data} = await getImageById(
        id,
        selectedImageType,
        cancelTokenSource
      );
      setSatusCode(status);
      if (data) {
        const fr = new FileReader();
        const onLoad = () => {
          setImage(fr.result);
        };
        fr.onload = onLoad;
        fr.readAsDataURL(data);
      }
    },
    [selectedImageType]
  );

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    fetchImage(imageId, cancelTokenSource);

    return () => {
      cancelTokenSource?.cancel();
    };
  }, [fetchImage, imageId]);

  return (
    <img
      role='presentation'
      onClick={() => {
        dispatch(selectImage(imageId));
      }}
      className={clsx(styles.image, {
        [styles.selected]: imageId === selectedImageId,
        [styles.issueCode]: statusCode === 422,
      })}
      alt={`${imageId} ${image ? '' : 'Loading...'}`}
      src={image}
    />
  );
};
