import React, {useEffect, useState} from 'react';
import {Button, Modal, CircularProgress} from '@material-ui/core';

import {DocInfoRequest, extractDoc} from '../../helpers/requester';
import styles from './DocInfoPanel.module.css';

export function DocInfoPanel({image, fileName}) {
  const [docData, setDocData] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingImg, setIsFetchingImg] = useState(false);
  const [extDoc, setExtDoc] = useState(false);
  const [extDocModalOpen, setExtDocModalOpen] = useState(false);

  const proccessDoc = async base64Image => {
    setIsFetching(true);

    const splitCropped = base64Image.split(',');
    const {data} = await DocInfoRequest({
      imageb64: splitCropped[1],
    });
    setDocData(data);
    setIsFetching(false);
  };

  const extractDocImg = async base64Image => {
    setIsFetchingImg(true);

    const splitCropped = base64Image.split(',');

    const {data} = await extractDoc({image: splitCropped[1]});

    if (data) {
      const fr = new FileReader();
      fr.onload = () => {
        setExtDoc(fr.result);
        setIsFetchingImg(false);
        setExtDocModalOpen(true);
      };
      fr.readAsDataURL(data);
    } else {
      setExtDoc(false);
      setIsFetchingImg(false);
    }
  };

  useEffect(() => {
    setExtDoc(false);
    if (image) {
      proccessDoc(image);
    }
  }, [image]);

  const getJsonData = () =>
    `data:text/json;charset=utf-8,${JSON.stringify(docData, null, '\t')}`;

  return (
    <div className={styles.proccessDocPanel}>
      <div className={styles.btnsRow}>
        <Button
          variant='outlined'
          className={styles.loadButton}
          disabled={!image}
          onClick={() => {
            extractDocImg(image);
          }}
        >
          {isFetchingImg ? <CircularProgress /> : 'Extract Doc'}
        </Button>

        {docData && (
          <a
            className={styles.loadButton}
            href={getJsonData()}
            download={`${fileName}.json`}
          >
            GET JSON
          </a>
        )}
      </div>
      {isFetching ? (
        <CircularProgress />
      ) : (
        docData && (
          <>
            <div className={styles.statusInfo}>Corners info</div>
            <div className={styles.list}>
              {docData?.corners?.map(({x, y}, i) => {
                return (
                  <div className={styles.rowStatusItem}>
                    <div className={styles.fieldColumn}>{i + 1}:</div>
                    <div className={styles.dataColumn}>
                      x:{x}
                      <br />
                      y:{y}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.statusInfo}>Cropbox info</div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Status:</div>
              <div className={styles.dataColumn}>
                {' '}
                {docData.cropbox.status.toString()}
              </div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Message:</div>
              <div className={styles.dataColumn}> {docData.cropbox.msg}</div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Box:</div>
              <div className={styles.dataColumn}>
                {' '}
                {JSON.stringify(docData.cropbox.box)}
              </div>
            </div>

            <div className={styles.statusInfo}>DocId info</div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Status:</div>
              <div className={styles.dataColumn}>
                {' '}
                {docData.docid.status.toString()}
              </div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Confidence:</div>
              <div className={styles.dataColumn}>
                {' '}
                {docData.docid.confidence}
              </div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>DocId:</div>
              <div className={styles.dataColumn}> {docData.docid.id}</div>
            </div>

            <div className={styles.statusInfo}>Status info</div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Quality:</div>
              <div className={styles.dataColumn}> {docData.quality.valid}</div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Status:</div>
              <div className={styles.dataColumn}>
                {' '}
                {docData.status.toString()}
              </div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Message:</div>
              <div className={styles.dataColumn}> {docData.msg}</div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Extract status:</div>
              <div className={styles.dataColumn}>
                {' '}
                {docData.extract_status.toString()}
              </div>
            </div>
            <div className={styles.rowStatusItem}>
              <div className={styles.fieldColumn}>Extract msg:</div>
              <div className={styles.dataColumn}> {docData.extract_msg}</div>
            </div>
          </>
        )
      )}

      <Modal
        className={styles.modal}
        onClose={() => {
          setExtDocModalOpen(false);
        }}
        open={extDocModalOpen}
      >
        <div className={styles.wrapper}>
          <img className={styles.image} alt='extructed' src={extDoc} />
        </div>
      </Modal>
    </div>
  );
}
