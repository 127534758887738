import {Modal, Button} from '@material-ui/core';
import {CheckCircleOutline, Warning} from '@material-ui/icons';
import React, {useState} from 'react';

import styles from './ProccessDocPanel.module.css';

const verify = ['verify1', 'verify2', 'verify3', 'verify4', 'verify5'];
const filter = ['filter1', 'filter2', 'filter3', 'filter4', 'filter5'];

const VALIDATION_STATUSES = {
  'ValidationStatusEnum.VALIDATE_NOVALIDATOR': (
    <CheckCircleOutline
      className={styles.validationStatus}
      htmlColor='orange'
      fontSize='large'
    />
  ),
  'ValidationStatusEnum.VALIDATE_OK': (
    <CheckCircleOutline
      className={styles.validationStatus}
      htmlColor='green'
      fontSize='large'
    />
  ),
  'ValidationStatusEnum.VALIDATE_FAIL': (
    <Warning
      className={styles.validationStatus}
      htmlColor='red'
      fontSize='large'
    />
  ),
};

const MRZ_MCC_STATUSES = {
  'MRZMachineCrossCheckStatus.MCC_NONE': '-',
  'MRZMachineCrossCheckStatus.MCC_FAILED': (
    <Warning
      className={styles.validationStatus}
      htmlColor='red'
      fontSize='large'
    />
  ),
  'MRZMachineCrossCheckStatus.MCC_OK': (
    <CheckCircleOutline
      className={styles.validationStatus}
      htmlColor='green'
      fontSize='large'
    />
  ),
};

const BARCODE_MCC_STATUSES = {
  'BarcodeMachineCrossCheckStatus.MCC_NONE': '-',
  'BarcodeMachineCrossCheckStatus.MCC_FAILED': (
    <Warning
      className={styles.validationStatus}
      htmlColor='red'
      fontSize='large'
    />
  ),
  'BarcodeMachineCrossCheckStatus.MCC_OK': (
    <CheckCircleOutline
      className={styles.validationStatus}
      htmlColor='green'
      fontSize='large'
    />
  ),
};

export function RegionsList({
  docData = {},
  regions = [],
  selectedTemplate,
  selectedPage,
  code = [],
  base64meta = '',
  openTemplate,
  disableOpenTemplate,
}) {
  const [imageModalOpen, setImageModalOpen] = useState(false);

  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
      <div className={styles.statusInfo}>Status info</div>
      <div className={styles.rowStatusItem}>
        <div className={styles.fieldColumn}>DocId:</div>
        <div className={styles.dataColumn}> {docData.docid}</div>
      </div>
      <div className={styles.rowStatusItem}>
        <div className={styles.fieldColumn}>Message:</div>
        <div
          className={styles.dataColumn}
          style={{color: docData.msg !== 'ok' && 'red'}}
        >
          {docData.msg}
        </div>
      </div>

      {openTemplate && (
        <Button
          variant='outlined'
          disabled={disableOpenTemplate}
          onClick={openTemplate}
        >
          Open template
        </Button>
      )}

      {/* <div role='presentation' className={styles.rowItem}>
        <div className={styles.fieldColumn}>Field</div>
        <div style={{width: '100%'}} className={styles.dataColumn}>
          Data
        </div>

        <div className={styles.dataColumn}>Bmcc</div>
        <div className={styles.dataColumn}>Mmcc</div>
        <div className={styles.dataColumn}>Vld</div>
      </div> */}
      <div className={styles.list}>
        {regions?.map(
          (
            {
              name,
              visual_data,
              bindata,
              mrz_mcc,
              mrz_data,
              barcode_mcc,
              bacode_data,
              visual_raw,
              validation,
              visual_dataeng,
              lang,
            },
            i
          ) => {
            const region = selectedTemplate?.[selectedPage]?.regions?.[i] || {};

            const verifyStr = verify
              .reduce((acc, field) => {
                const codeName =
                  code.find(({id}) => {
                    return id === region[field];
                  })?.name || '';

                if (codeName) acc.push(codeName);
                return acc;
              }, [])
              .join(', ');

            const filterStr = filter
              .reduce((acc, field) => {
                const codeName =
                  code.find(({id}) => {
                    return id === region[field];
                  })?.name || '';

                if (codeName) acc.push(codeName);
                return acc;
              }, [])
              .join(', ');

            const covertorStr =
              code.find(({id}) => {
                return id === region.converter;
              })?.name || '';

            return (
              <div
                role='presentation'
                onClick={() => {
                  setImageModalOpen({
                    open: true,
                    bindata,
                    visual_data,
                    visual_raw,
                    validation,
                    name,
                    visual_dataeng,
                    lang,
                  });
                }}
                key={name}
                className={styles.rowItem}
              >
                <div className={styles.fieldColumn}>{name}:</div>
                <div className={styles.dataColumn}>
                  {visual_data || '-none-'}
                </div>

                <div className={styles.dataColumn}>
                  {BARCODE_MCC_STATUSES[barcode_mcc]}
                  {bacode_data && (
                    <div>
                      <b>bacode_data:</b>
                      <br />
                      {bacode_data}
                      <br />
                    </div>
                  )}
                </div>
                <div className={styles.dataColumn}>
                  {MRZ_MCC_STATUSES[mrz_mcc]}
                  {mrz_data && (
                    <div>
                      <b>mrz_data:</b>
                      <br />
                      {mrz_data}
                      <br />
                    </div>
                  )}
                </div>
                {VALIDATION_STATUSES[validation?.type]}
                {(verifyStr || filterStr || covertorStr) && (
                  <div>
                    {verifyStr && (
                      <>
                        <b>Verify:</b>
                        <br />
                        {verifyStr}
                        <br />
                      </>
                    )}
                    {filterStr && (
                      <>
                        <b>Filter:</b>
                        <br />
                        {filterStr}
                        <br />
                      </>
                    )}
                    {covertorStr && (
                      <>
                        <b>Converter:</b>
                        <br />
                        {covertorStr}
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>

      <Modal
        className={styles.modal}
        onClose={() => {
          setImageModalOpen(false);
        }}
        open={!!imageModalOpen?.open}
      >
        <div className={styles.wrapper}>
          <img
            className={styles.image}
            alt={imageModalOpen?.name}
            src={`${base64meta},${imageModalOpen?.bindata}`}
          />
          <table>
            <tr>
              <td>Visual Data:</td>
              <td>{imageModalOpen?.visual_data}</td>
            </tr>
            <tr>
              <td>Visual Raw:</td>
              <td>{imageModalOpen?.visual_raw}</td>
            </tr>
            <tr>
              <td>Visual Dataeng:</td>
              <td>{imageModalOpen?.visual_dataeng}</td>
            </tr>
            <tr>
              <td>Lang:</td>
              <td>{imageModalOpen?.lang}</td>
            </tr>
            <tr>
              <td>Validation:</td>
              <td>{imageModalOpen?.validation?.msg}</td>
            </tr>
          </table>
        </div>
      </Modal>
    </div>
  );
}
