import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {ZoomOutOutlined, ZoomInOutlined} from '@material-ui/icons';
import ReactCrop from 'react-image-crop';

import {getTemplate, loadTemplatesForCountry} from '../../slices/templateSlice';
import {setTemplateImageProps} from '../../helpers/requester';
import styles from './ImageCropper.module.css';
import 'react-image-crop/dist/ReactCrop.css';

// const imageTypesWithBoxes = ['EXTRUCTED', 'SIDES', 'REGULAR'];

export function Cropper(props) {
  const dispatch = useDispatch();
  const {selectedImageId, selectedCountry} = useSelector(getTemplate);
  const [crop, setCrop] = useState({});
  const [zoom, setZoom] = useState(65);
  const {
    selectedRegion,
    regions,
    isFetching,
    onRegionChange,
    image,
    status,
    isImageValid,
    editRegion,
    selectRegion,
  } = props;

  const boxesVisible = true;
  // useMemo(
  //   () => imageTypesWithBoxes.includes(selectedImageType),
  //   [selectedImageType]
  // );

  useEffect(() => {
    if (selectedRegion && boxesVisible) {
      const {x, y, width, height} = selectedRegion;

      setCrop({
        x: x * 100,
        y: y * 100,
        width: width * 100,
        height: height * 100,
        unit: '%',
      });
    } else {
      setCrop({});
    }
  }, [selectedRegion, boxesVisible]);

  const handleChange = async e => {
    await setTemplateImageProps({
      id: selectedImageId,
      document_visible: e.target.checked,
    });
    dispatch(loadTemplatesForCountry(selectedCountry));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%',
        height: '100%',
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.zoomButtons}>
          <Button
            variant='outlined'
            onClick={() => {
              setZoom(zoom - 20);
            }}
          >
            <ZoomOutOutlined />
          </Button>

          <Button
            variant='outlined'
            onClick={() => {
              setZoom(zoom + 20);
            }}
          >
            <ZoomInOutlined />
          </Button>
        </div>

        {isFetching ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              width: `${zoom}%`,
              minWidth: `${zoom}%`,
              border: status === 422 && '5px solid red',
            }}
            className={styles.cropperWrapper}
          >
            {regions &&
              boxesVisible &&
              regions.map(regionObj => {
                const {
                  x,
                  y,
                  width,
                  height,
                  id,
                  localId,
                  type: {name},
                } = regionObj;

                const commonStyles = {
                  zIndex: 999,
                  position: 'absolute',
                  left: `${x * 100}%`,
                  top: `${y * 100}%`,
                  width: `${width * 100}%`,
                  height: `${height * 100}%`,
                };

                return (id && selectedRegion?.id !== id) ||
                  (localId && selectedRegion?.localId !== localId) ? (
                  <div
                    role='presentation'
                    onClick={() => {
                      selectRegion(regionObj);
                    }}
                    onDoubleClick={editRegion}
                    key={id}
                    style={{
                      ...commonStyles,
                      border: '2px solid red',
                      boxSizing: 'border-box',
                      color: '#ffffff',
                      fontWeight: 'bold',
                    }}
                  >
                    {name}
                  </div>
                ) : (
                  <div
                    role='presentation'
                    onDoubleClick={editRegion}
                    key={id}
                    style={{...commonStyles, pointerEvents: 'none'}}
                  />
                );
              })}

            <ReactCrop
              disabled={!boxesVisible || !selectedRegion}
              className={styles.cropper}
              src={image || '/logo512.png'}
              crop={crop}
              keepSelection
              imageStyle={{width: '100%'}}
              locked={!selectedRegion}
              onChange={(pxCrop, percentCrop) => {
                setCrop(percentCrop);
              }}
              onImageLoaded={() => false}
              onComplete={(qwe, newCrop) => {
                newCrop.width &&
                  newCrop.height &&
                  onRegionChange({
                    x: newCrop.x / 100,
                    y: newCrop.y / 100,
                    width: newCrop.width / 100,
                    height: newCrop.height / 100,
                  });
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.settingsWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!selectedImageId}
              checked={isImageValid}
              onChange={handleChange}
              name='IsImgValid'
              color='primary'
            />
          }
          label='Is image valid'
        />
      </div>
    </div>
  );
}
