import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {TextField, Button, Paper, Tabs, Tab} from '@material-ui/core';
import {loginAsync, loginWithKey, getCurrentUser} from '../../slices/userSlice';
import styles from './LoginPage.module.css';

export function Login() {
  const user = useSelector(getCurrentUser);

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  return user?.token ? (
    <Redirect to='/app' />
  ) : (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <Tabs
          indicatorColor='primary'
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          textColor='primary'
          value={selectedTab}
        >
          <Tab label='With password' />
          <Tab label='With ApiKey' />
        </Tabs>

        {selectedTab === 0 && (
          <>
            <TextField
              label='name'
              variant='outlined'
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              fullWidth
            />
            <TextField
              label='password'
              variant='outlined'
              fullWidth
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              type='password'
            />
          </>
        )}
        {selectedTab === 1 && (
          <TextField
            label='API key'
            variant='outlined'
            fullWidth
            value={apiKey}
            onChange={e => {
              setApiKey(e.target.value);
            }}
            // type='password'
          />
        )}

        <Button
          disabled={selectedTab === 1 ? !apiKey : !name || !password}
          variant='contained'
          onClick={() =>
            dispatch(
              selectedTab === 1
                ? loginWithKey(apiKey)
                : loginAsync({username: name, password})
            )
          }
        >
          Login
        </Button>
      </Paper>
    </div>
  );
}
