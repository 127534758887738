import React, {useEffect, useState, useCallback} from 'react';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {useDispatch, useSelector} from 'react-redux';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  CartesianGrid,
  BarChart,
  Bar,
  Legend,
} from 'recharts';

import {Link} from 'react-router-dom';
import {
  Modal,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';

import {
  getImage,
  getCountriesRequest,
  getTemplatesForCountryRequest,
} from '../../helpers/requester';
import styles from './ReportsPage.module.css';

import {ReactComponent as OkIcon} from '../../images/ok_icon.svg';
import {ReactComponent as WarningIcon} from '../../images/warning_icon.svg';

import {RegionsList} from '../ProccessDocPanel/RegionsList';
import {
  selectPage,
  selectTemplate,
  selectCountry,
} from '../../slices/templateSlice';
import {
  getReportsState,
  getDictionaryRequest,
  getReportsRequest,
  getReportsByTypeRequest,
  setPage,
  setPageSize,
  setFilters,
  setImageModalOpen,
  getReportsByDateRequest,
  getReportsStatsRequest,
} from '../../slices/reportsSlice';

const CustomTooltip = ({active, payload, label}) => {
  const values = payload?.[0]?.payload;

  if (active && payload && payload.length) {
    return (
      <div className={styles.barTooltipWrapper}>
        <p className={styles.barTooltipLabel}>{label}</p>
        {Object.keys(values).map(key => {
          if (key === 'name') return null;
          return (
            <p className={styles.barTooltipValue}>
              {key}:{values[key]}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

const rowsPerPageOptions = [10, 15, 20, 25, 100, 500, 1000, 1500, 2000];

const typeColumns = [
  {
    id: 'name',
    label: 'name',
  },
  {
    id: 'count',
    label: 'count',
  },
];

const columns = [
  {
    id: 'status',
    label: 'status',
    format: value => (value ? <OkIcon /> : <WarningIcon />),
  },
  {
    id: 'date',
    label: 'date',
    format: value => moment(value).format('DD-MMM-YYYY HH:mm'),
  },
  {
    id: 'source',
    label: 'source',
  },
  {
    id: 'country',
    label: 'country',
  },
  {
    id: 'type',
    label: 'type',
  },
  {
    id: 'userdata',
    label: 'userdata',
  },
  {
    id: 'error',
    label: 'error',
  },
  {
    id: 'company',
    label: 'company',
  },
  {
    id: 'screening_token',
    label: 'screening token',
  },
  {
    id: 'jsdata',
    label: 'jsdata',
    format: value => {
      const parsedData = JSON.parse(value);
      const {docid, errors} = parsedData;

      return (
        (typeof errors === 'number' && (
          <>
            <p>
              <b>Docid: </b>
              {docid}
            </p>
            <p>
              <b>Errors: </b>
              {errors}
            </p>
          </>
        )) ||
        ''
      );
    },
  },
  {
    id: 'complain',
    label: 'complain',
    format: value =>
      value ? <WarningIcon htmlColor='red' fontSize='large' /> : '',
  },
  {
    id: 'scanid',
    label: 'scanid',
  },
];

// Helper function to convert base64 to Blob
const base64ToBlob = base64String => {
  const byteCharacters = atob(base64String.split(',')[1]);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray], {type: 'image/jpeg'}); // Change the type if needed
};

const multipleSelectMenuProps = {className: 'multiple-select'};

export function ImageModal({imageId, imageid_ext}) {
  const [img, setImg] = useState('');
  const [imgExt, setImgExt] = useState('');

  const fetchImage = async id => {
    const {data} = await getImage(id);

    if (data) {
      return new Promise(resolve => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsDataURL(data);
      });
    }

    return '';
  };

  useEffect(async () => {
    const imag = await fetchImage(imageId);
    setImg(imag);
  }, [imageId]);

  useEffect(async () => {
    if (imageid_ext) {
      const imgageExt = await fetchImage(imageid_ext);
      setImgExt(imgageExt);
    }
  }, [imageid_ext]);

  const handleZipDownload = () => {
    if (imageid_ext && img) {
      const remoteUrls = [img, imgExt];
      const zip = new JSZip();

      remoteUrls.forEach((base64String, index) => {
        const blob = base64ToBlob(base64String);
        zip.file(index ? `${imageid_ext}.jpg` : `${imageId}.jpg`, blob);
      });

      zip.generateAsync({type: 'blob'}).then(content => {
        saveAs(content, 'downloaded_images.zip');
      });
      return;
    }

    const blob = base64ToBlob(img);
    saveAs(blob, `${imageId}.jpg`);
  };

  return (
    <div className={styles.imageWrapper}>
      {(!imageid_ext && img) || (img && imgExt) ? (
        <>
          <div className={styles.buttonsWrapper}>
            <button
              type='button'
              onClick={handleZipDownload}
              className={styles.loadButton}
            >
              GET RAW IMAGE
            </button>
            <Link
              className={styles.loadButton}
              to={`/process_doc?imageid=${imageId}&imageid_back=${
                imageid_ext || ''
              }`}
            >
              Process Image
            </Link>
          </div>
          <div className={styles.imagesWrapper}>
            <img
              className={imgExt ? styles.doubleImage : styles.singleImage}
              alt={imageId}
              src={img}
            />
            {imgExt && (
              <img
                className={styles.doubleImage}
                alt={imageid_ext}
                src={imgExt}
              />
            )}
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export function ReportsPage({history}) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [chartSize, setChartSize] = useState({width: 900, height: 500});

  const {
    dictionary,
    filters,
    reportsByType,
    reportsByDate,
    reportsStats,
    reports,
    reportsCount,
    page,
    pageSize,
    imageModalOpen,
  } = useSelector(getReportsState);
  const {
    fromdate,
    todate,
    countries,
    sources,
    userdata,
    types,
    errors,
    status,
    hint,
    mrz,
    complain,
    scanid,
    typename,
  } = filters;

  const onChangePageOrPageSize = useCallback(async () => {
    const commonFilter = {
      fromdate: moment(fromdate).format('MM-DD-Y'),
      todate: moment(todate).format('MM-DD-Y'),
      errors,
      status: typeof status === 'boolean' ? status : null,
      hint: hint || null,
      mrz: mrz || null,
      countries,
      sources,
      userdata,
      types,
      complain: typeof complain === 'boolean' ? complain : null,
      scanid: scanid || null,
    };

    dispatch(getReportsRequest({...commonFilter, page: page + 1, pageSize}));
  }, [
    dispatch,
    fromdate,
    todate,
    errors,
    status,
    hint,
    mrz,
    countries,
    sources,
    userdata,
    types,
    page,
    pageSize,
    complain,
    scanid,
  ]);

  useEffect(() => {
    onChangePageOrPageSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    dispatch(getDictionaryRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab === 2 || selectedTab === 3) {
      const chartWrapper = document.getElementById('charts-wrapper');

      if (chartWrapper) {
        setChartSize({
          width: chartWrapper.clientWidth,
          height: chartWrapper.clientHeight,
        });
      }
    }
  }, [selectedTab]);

  const handleGetReports = async () => {
    const commonFilter = {
      fromdate: moment(fromdate).format('MM-DD-Y'),
      todate: moment(todate).format('MM-DD-Y'),
      errors,
      status: typeof status === 'boolean' ? status : null,
      hint: hint || null,
      mrz: mrz || null,
      countries,
      sources,
      userdata,
      types,
      complain: typeof complain === 'boolean' ? complain : null,
      scanid: scanid || null,
    };

    if (selectedTab === 0) {
      dispatch(getReportsRequest({...commonFilter, page: page + 1, pageSize}));
      dispatch(setPage(0));
    }

    if (selectedTab === 1) {
      dispatch(
        getReportsByTypeRequest({
          ...commonFilter,
          typename,
        })
      );
    }

    if (selectedTab === 2) {
      dispatch(getReportsByDateRequest(commonFilter));
    }

    if (selectedTab === 3) {
      dispatch(getReportsStatsRequest(commonFilter));
    }
  };

  const handleResetFilters = () => {
    dispatch(
      setFilters({
        fromdate: moment().add(-7, 'd').toString(),
        todate: moment().toString(),
        countries: [],
        sources: [],
        userdata: [],
        types: [],
        errors: [],
        status: '',
        hint: '',
        mrz: '',
        complain: '',
        scanid: '',
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangePageSize = event => {
    dispatch(setPageSize(+event.target.value));
    dispatch(setPage(0));
  };

  const handleChangeFilterField = event => {
    const {name, value} = event.target;
    dispatch(setFilters({[name]: value}));
  };

  return (
    <div className={styles.reportsPage}>
      <div className={styles.filtersWrapper}>
        <div
          style={{
            display: 'flex',
            width: '95%',
            flexDirection: 'column',
            rowGap: '10px',
          }}
        >
          <div style={{display: 'flex', columnGap: '20px'}}>
            <DatePicker
              className='DatePicker'
              disableFuture
              fullWidth
              label='Date from'
              inputVariant='outlined'
              value={fromdate}
              onChange={value => {
                dispatch(setFilters({fromdate: value.toString()}));
              }}
            />
            <DatePicker
              className='DatePicker'
              disableFuture
              fullWidth
              label='Date to'
              inputVariant='outlined'
              value={todate}
              onChange={value => {
                dispatch(setFilters({todate: value.toString()}));
              }}
            />

            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select countries'>Select countries</InputLabel>
              <Select
                multiple
                MenuProps={multipleSelectMenuProps}
                labelId='Select countries'
                label='Select countries'
                fullWidth
                value={countries}
                name='countries'
                onChange={handleChangeFilterField}
              >
                {dictionary.countries.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select types'>Select types</InputLabel>
              <Select
                multiple
                MenuProps={multipleSelectMenuProps}
                labelId='Select types'
                label='Select types'
                fullWidth
                value={types}
                name='types'
                onChange={handleChangeFilterField}
              >
                {dictionary.types.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select userdata'>Select userdata</InputLabel>
              <Select
                multiple
                MenuProps={multipleSelectMenuProps}
                labelId='Select userdata'
                label='Select userdata'
                fullWidth
                value={userdata}
                name='userdata'
                onChange={handleChangeFilterField}
              >
                {dictionary.userdata.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select sources'>Select sources</InputLabel>
              <Select
                multiple
                MenuProps={multipleSelectMenuProps}
                labelId='Select sources'
                label='Select sources'
                fullWidth
                value={sources}
                name='sources'
                onChange={handleChangeFilterField}
              >
                {dictionary.sources.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select errors'>Select errors</InputLabel>
              <Select
                multiple
                MenuProps={multipleSelectMenuProps}
                labelId='Select errors'
                label='Select errors'
                fullWidth
                value={errors}
                name='errors'
                onChange={handleChangeFilterField}
              >
                {dictionary.errors.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select status'>Select status</InputLabel>
              <Select
                labelId='Select status'
                label='Select status'
                fullWidth
                value={status}
                name='status'
                onChange={handleChangeFilterField}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {['True', 'False'].map(name => (
                  <MenuItem key={name} value={name === 'True'}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', columnGap: '20px'}}>
            <TextField
              className='TextInput'
              fullWidth
              label='hint'
              variant='outlined'
              value={hint}
              name='hint'
              onChange={handleChangeFilterField}
            />
            <TextField
              className='TextInput'
              fullWidth
              label='mrz'
              variant='outlined'
              value={mrz}
              name='mrz'
              onChange={handleChangeFilterField}
            />
            <TextField
              className='TextInput'
              fullWidth
              label='scanid'
              variant='outlined'
              value={scanid}
              name='scanid'
              onChange={handleChangeFilterField}
            />

            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='Select complain'>Select complain</InputLabel>
              <Select
                labelId='Select complain'
                label='Select complain'
                fullWidth
                value={complain}
                name='complain'
                onChange={handleChangeFilterField}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {['True', 'False'].map(name => (
                  <MenuItem key={name} value={name === 'True'}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Button variant='outlined' onClick={handleResetFilters}>
          Reset Filters
        </Button>
        <Button
          disabled={selectedTab === 1 && !typename}
          variant='outlined'
          onClick={handleGetReports}
        >
          Get reports
        </Button>
      </div>

      <Tabs
        indicatorColor='primary'
        onChange={(e, value) => {
          setSelectedTab(value);
        }}
        textColor='primary'
        value={selectedTab}
      >
        <Tab label='Reports' />
        <Tab label='Reports by type' />
        <Tab label='Reports by date' />
        <Tab label='Stats by date' />
      </Tabs>

      {selectedTab === 0 && (
        <>
          <TableContainer className='reports-table' sx={{maxHeight: 440}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map(row => {
                  const {
                    imageid,
                    imageid_ext,
                    country,
                    jsdata,
                    date,
                    status: rowStatus,
                  } = row;
                  const {regions, ...docData} = JSON.parse(jsdata);

                  return (
                    <TableRow
                      onClick={() => {
                        dispatch(
                          setImageModalOpen({
                            imageId: imageid,
                            imageid_ext,
                            regions,
                            docData,
                            country,
                          })
                        );
                      }}
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={date}
                      className={
                        rowStatus ? styles.validRow : styles.invalidRow
                      }
                    >
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <>{column.format ? column.format(value) : value}</>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={reportsCount}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangePageSize}
          />
        </>
      )}

      {selectedTab === 1 && (
        <div className={styles.reportsByTypeWrapper}>
          <FormControl className='DropDown' fullWidth variant='outlined'>
            <InputLabel id='Select typename'>Select typename</InputLabel>
            <Select
              labelId='Select typename'
              label='Select typename'
              fullWidth
              value={typename}
              name='typename'
              onChange={handleChangeFilterField}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {[
                'countries',
                'types',
                'sources',
                'userdata',
                'status',
                'complain',
                'errors',
              ].map(name => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TableContainer className='reports-table' sx={{maxHeight: 440}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {typeColumns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsByType.map(row => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      {typeColumns.map(column => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align}>
                            <>{column.format ? column.format(value) : value}</>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {selectedTab === 2 && (
        <div>
          <div style={{width: '90%', margin: '50px'}} id='charts-wrapper'>
            <LineChart
              width={900}
              height={500}
              data={reportsByDate}
              margin={{top: 5, right: 20, left: 10, bottom: 5}}
              {...chartSize}
            >
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke='#f5f5f5' />
              <Line type='monotone' dataKey='count' stroke='#ff7300' />
            </LineChart>
          </div>
        </div>
      )}

      {selectedTab === 3 && (
        <div>
          <div style={{width: '90%', margin: '50px'}} id='charts-wrapper'>
            <BarChart
              width={900}
              height={500}
              data={reportsStats}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              {...chartSize}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey='validPercent' fill='#82ca9d' />
            </BarChart>
          </div>
        </div>
      )}

      <Modal
        className={styles.modal}
        onClose={() => {
          dispatch(setImageModalOpen(false));
        }}
        open={!!imageModalOpen}
      >
        <div className={styles.modalContent}>
          <ImageModal
            imageId={imageModalOpen.imageId}
            imageid_ext={imageModalOpen.imageid_ext}
          />

          <RegionsList
            openTemplate={async () => {
              const {docData, country} = imageModalOpen;

              if (country) {
                const pg = docData.docid.split('.').pop();

                const {data: ctrs} = await getCountriesRequest();
                const ctrId = ctrs.find(({name}) => name === country).id;

                const {data: tplts} = await getTemplatesForCountryRequest(
                  ctrId
                );
                const tpl = tplts.find(
                  template => template[pg].searchId === docData.docid
                );

                dispatch(selectCountry(ctrId));
                dispatch(selectTemplate(tpl));
                dispatch(selectPage(pg));

                history.push('/app');
              }
            }}
            disableOpenTemplate={!imageModalOpen.country}
            docData={imageModalOpen.docData}
            regions={imageModalOpen.regions}
          />
        </div>
      </Modal>
    </div>
  );
}
