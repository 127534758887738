import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../slices/userSlice';
import templateReducer from '../slices/templateSlice';
import reportsReducer from '../slices/reportsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    templates: templateReducer,
    reports: reportsReducer,
  },
});
