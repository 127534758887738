import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {loginRequest, getUserMode, logoutRequest} from '../helpers/requester';

const initialState = {
  token: localStorage.getItem('token') || localStorage.getItem('key'),
  writemode: localStorage.getItem('writemode') === 'true',
  isExtractPanelVisible:
    localStorage.getItem('isExtractPanelVisible') === 'true',
  isProcessPanelVisible:
    localStorage.getItem('isProcessPanelVisible') === 'true',
  isFetching: false,
};

export const loginAsync = createAsyncThunk(
  'user/loginWithPass',
  async payload => {
    const response = await loginRequest(payload);

    if (response.data) {
      localStorage.setItem('token', response.data.token);

      const mode = await getUserMode();
      localStorage.setItem('writemode', mode.data?.writemode);
      localStorage.removeItem('key');

      return {...response.data, ...mode.data};
    }

    return response.data;
  }
);

export const loginWithKey = createAsyncThunk('user/loginWithKey', async key => {
  localStorage.setItem('key', key);

  const {data, isError} = await getUserMode();

  if (isError) {
    localStorage.removeItem('key');

    return {};
  }

  localStorage.setItem('writemode', data.writemode);
  localStorage.removeItem('token');

  return {token: key, ...data};
});

export const logoutAsync = createAsyncThunk('user/logout', async () => {
  await logoutRequest();

  localStorage.removeItem('key');
  localStorage.removeItem('token');
  localStorage.removeItem('writemode');
});

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setExtractPanelVisible: (state, action) => {
      state.isExtractPanelVisible = action.payload;
    },
    setProcessPanelVisible: (state, action) => {
      state.isProcessPanelVisible = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.writemode = action.payload.writemode;
    });

    builder.addCase(loginWithKey.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.writemode = action.payload.writemode;
    });

    builder.addCase(logoutAsync.fulfilled, state => {
      state.token = '';
      state.writemode = '';
    });
  },
});

export const {setExtractPanelVisible, setProcessPanelVisible} =
  userSlice.actions;

export const getCurrentUser = state => state.user;

export default userSlice.reducer;
