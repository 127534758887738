import React from 'react';
import ReactDOM from 'react-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {Provider, useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {getCurrentUser} from './slices/userSlice';
import {CodePage} from './components/CodePage/CodePage';
import {ProcessDocPage} from './components/ProcessDocPage/ProcessDocPage';
import App from './App';
import {store} from './app/store';
import {Header} from './components/Header/Header';
import {TemplatesPanel} from './components/TemplatesPanel/TemplatesPanel';
import {ReportsPage} from './components/ReportsPage/ReportsPage';
import {OnlineTemplatesPage} from './components/OnlineTemplatesPage/OnlineTemplatesPage';
import {UploadPage} from './components/UploadPage/UploadPage';
import {Login} from './components/LoginPage/Login';
import './index.css';
import {HoloMarkupPage} from './components/HoloMarkupPage/HoloMarkupPage';

// todo add secure routes

const AppRoutes = ({history}) => {
  const user = useSelector(getCurrentUser);

  return user?.token ? (
    <div className='App'>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          overflow: 'hidden',
          flexDirection: 'column',
        }}
      >
        <Header location={history.location} />
        <Switch>
          <Route path='/holo-markup'>
            <HoloMarkupPage history={history.history} />
          </Route>
          <Route path='/upload'>
            <UploadPage history={history.history} />
          </Route>
          <Route path='/online_templates'>
            <OnlineTemplatesPage history={history.history} />
          </Route>
          <Route path='/reports'>
            <ReportsPage history={history.history} />
          </Route>
          <Route path='/process_doc'>
            <ProcessDocPage />
          </Route>

          <Route exact path='/app'>
            {user?.writemode ? (
              <>
                <TemplatesPanel />
                <App />
              </>
            ) : (
              <Redirect to='/process_doc' />
            )}
          </Route>
          <Route path='/code'>
            {user?.writemode ? <CodePage /> : <Redirect to='/process_doc' />}
          </Route>

          <Route path='/'>
            <Redirect to={user?.writemode ? '/app' : '/process_doc'} />
          </Route>
        </Switch>
      </div>
    </div>
  ) : (
    <Redirect to='/login' />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Switch>
            <Route path='/login'>
              <Login />
            </Route>
            <Route
              render={history => <AppRoutes history={history} />}
              path='/*'
            />
          </Switch>
        </Router>
        <ToastContainer />
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
