import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {toast} from 'react-toastify';
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
} from '@material-ui/core';
import {EditorState} from '@codemirror/state';
import {EditorView, keymap} from '@codemirror/view';
import {indentWithTab} from '@codemirror/commands';
import {basicSetup} from '@codemirror/basic-setup';
import {python} from '@codemirror/lang-python';

import {loadDictionaries, getTemplate} from '../../../slices/templateSlice';
import {createCode, updateCode} from '../../../helpers/requester';
import styles from './EditCodeModal.module.css';

export function EditCodeModal({handleClose, selectedCode, getCodeAsync}) {
  const {dictionaries} = useSelector(getTemplate);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadDictionaries());
  }, [dispatch]);
  const [view, setView] = useState(null);

  const editor = useRef();

  useEffect(() => {
    // const log = event => console.log(event);
    // editor.current.addEventListener('input', log);

    const state = EditorState.create({
      doc: selectedCode.code || '# Write your code here \n\n\n\n\n\n\n\n\n',
      extensions: [basicSetup, keymap.of([indentWithTab]), python()],
    });
    const editorView = new EditorView({state, parent: editor.current});

    setView(editorView);

    return () => {
      // editor.current.removeEventListener("input", log);
      editorView.destroy();
    };
  }, [selectedCode.code, setView]);

  const [name, setName] = useState(selectedCode.name || '');
  const [desc, setDesc] = useState(selectedCode.desc || '');
  const [type, setType] = useState(selectedCode.type || '');

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* <button
            type='button'
            onClick={() => {
              console.log(view.state.sliceDoc());
            }}
          >
            qweqweqweqwe
          </button> */}
          <FormControl
            className={`${styles.formControl} DropDown`}
            variant='outlined'
          >
            <InputLabel id='Select type'>Select type</InputLabel>
            <Select
              labelId='Select type'
              label='Select type'
              fullWidth
              value={type}
              onChange={e => {
                setType(e.target.value);
              }}
            >
              {dictionaries?.pycode?.map(([value, dictName]) => (
                <MenuItem key={value} value={value}>
                  {dictName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={styles.formControl}
            label='name'
            variant='outlined'
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <TextField
            className={styles.descField}
            label='desc'
            variant='outlined'
            value={desc}
            onChange={e => {
              setDesc(e.target.value);
            }}
          />
          <div className={styles.buttonsWrapper}>
            <Button
              disabled={selectedCode?.static}
              variant='contained'
              onClick={async () => {
                const {isError} = selectedCode
                  ? await updateCode({
                      ...selectedCode,
                      name,
                      desc,
                      code: view.state.sliceDoc(),
                      type,
                    })
                  : await createCode({
                      name,
                      desc,
                      code: view.state.sliceDoc(),
                      type,
                    });
                await getCodeAsync();

                if (!isError) {
                  toast.success(selectedCode ? 'Code updated' : 'Code created');
                  handleClose();
                }
              }}
            >
              {selectedCode ? 'Update code' : 'Create code'}
            </Button>
            <Button variant='contained' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>

        <div
          style={{width: '100%', height: '60vh', overflow: 'auto'}}
          ref={editor}
        />
      </Paper>
    </div>
  );
}
