import React from 'react';
import {Slider, Grid, Input, Typography} from '@material-ui/core';

export function SliderSettings({
  value = {},
  setValue,
  name,
  min = 0,
  max = 100,
  step = 0.1,
}) {
  const handleSliderChange = (event, newValue) => {
    setValue({...value, [name]: newValue});
  };

  const handleInputChange = event => {
    setValue({
      ...value,
      [name]: event.target.value === '' ? '' : Number(event.target.value),
    });
  };

  const handleBlur = () => {
    if (value < min) {
      setValue({...value, [name]: min});
    } else if (value > max) {
      setValue({...value, [name]: max});
    }
  };

  return (
    <div style={{minWidth: '200px', textAlign: 'center'}}>
      <Typography id='input-slider' gutterBottom>
        Param {name.split('_').pop()}
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid xs={8} item>
          <Slider
            aria-label='Always visible'
            step={step}
            min={min}
            max={max}
            valueLabelDisplay='on'
            value={value[name] || min}
            onChange={handleSliderChange}
            aria-labelledby='input-slider'
          />
        </Grid>
        <Grid xs={4} item>
          <Input
            value={value[name] || min}
            size='small'
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step,
              min,
              max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
