import React, {useState, useEffect, useCallback} from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
} from '@material-ui/core';
import {getImageRegionTypes} from '../../../helpers/requester';
import styles from './LoginPage.module.css';

export function AddRegion({handleAddRegion, handleClose}) {
  const [regionTypes, setRegionTypes] = useState([]);
  const [region, setRegion] = useState('');

  const getTypes = useCallback(async () => {
    const {data} = await getImageRegionTypes();
    setRegionTypes(data);
  }, []);

  useEffect(() => {
    getTypes();
  }, [getTypes]);

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <div className='modal-title'>Add region</div>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>
            Select region to add
          </InputLabel>
          <Select
            labelId='select-country-label'
            label='Select region to add'
            value={region}
            onChange={e => {
              setRegion(e.target.value);
            }}
          >
            {regionTypes.map(({id, name: regName}) => (
              <MenuItem key={id} value={id}>
                {regName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.buttonsWrapper}>
          <Button
            variant='contained'
            onClick={async () => {
              const {
                format,
                id: regId,
                importance,
                name,
                ...defaultAttrs
              } = regionTypes.find(({id}) => id === region);

              handleAddRegion({
                type: {format, regId, importance, name},
                ...defaultAttrs,
                localId: Date.now(),
                x: 0.4,
                y: 0.4,
                width: 0.2,
                height: 0.2,
                unit: '%',
                hints: 2,
              });
              handleClose();
            }}
          >
            Add region
          </Button>

          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>
    </div>
  );
}
