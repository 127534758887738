import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  getCountriesRequest,
  getDocumentTypesRequest,
  getTemplatesForCountryRequest,
  getDict,
} from '../helpers/requester';

export const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const initialState = {
  documentTypes: [],
  countries: [],
  templatesForCountry: [],
  selectedCountry: '',
  selectedTemplate: null,
  selectedPage: '', // 'front' or 'back'
  selectedImageId: '',
  selectedImageType: 'SIDES',
  selectedRegion: null,
  regionsChanged: false,
  dictionaries: {},
  hideInvalidImages: false,
};

export const loadDocumentTypes = createAsyncThunk(
  'templates/loadDocumentTypes',
  async () => {
    const response = await getDocumentTypesRequest();

    return response?.data || [];
  }
);

export const loadCountries = createAsyncThunk(
  'templates/loadCountries',
  async () => {
    const response = await getCountriesRequest();

    return (
      response.data?.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      }) || []
    );
  }
);

export const loadTemplatesForCountry = createAsyncThunk(
  'templates/loadTemplatesForCountry',
  async id => {
    const response = await getTemplatesForCountryRequest(id);

    return response.data || [];
  }
);

export const loadDictionaries = createAsyncThunk(
  'templates/loadDictionaries',
  async () => {
    const response = await getDict();

    return response.data || {};
  }
);

export const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    selectCountry: (state, action) => {
      state.selectedCountry = action.payload;
      state.selectedTemplate = null;
      state.selectedPage = '';
      state.selectedImageId = '';
      state.selectedRegion = null;
      state.templatesForCountry = [];
    },
    selectTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
      state.selectedRegion = null;
    },
    selectPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    selectImage: (state, action) => {
      state.selectedImageId = action.payload;
    },
    selectRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    setImageType: (state, action) => {
      state.selectedImageType = action.payload;
    },
    setRegionsChanged: (state, action) => {
      state.regionsChanged = action.payload;
    },
    setHideInvalidImages: (state, action) => {
      state.hideInvalidImages = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(loadDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes = action.payload;
      })
      .addCase(loadCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(loadDictionaries.fulfilled, (state, action) => {
        state.dictionaries = action.payload;
      })
      .addCase(loadTemplatesForCountry.fulfilled, (state, action) => {
        state.templatesForCountry = action.payload.sort(sortByName);
        if (state.selectedTemplate) {
          state.selectedTemplate = state.templatesForCountry.find(
            ({id}) => id === state.selectedTemplate.id
          );
        }
        if (state.selectedRegion && state.selectedPage) {
          state.selectedRegion = state.selectedTemplate[
            state.selectedPage
          ].regions.find(({id}) => {
            return id === state.selectedRegion.id;
          });
        }
      });
  },
});

export const {
  selectCountry,
  setImageType,
  setRegionsChanged,
  selectTemplate,
  selectRegion,
  selectPage,
  selectImage,
  setHideInvalidImages,
} = templateSlice.actions;

export const getTemplate = state => state.templates;

export default templateSlice.reducer;
