/* eslint-disable jsx-a11y/media-has-caption */
import React, {useCallback, useEffect, useState} from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import ReactCrop from 'react-image-crop';

import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import {
  getUuidForHolo,
  getHoloByUuid,
  postVideoRequest,
  getTemplates,
  getSelectedTemplate,
  saveTemplate,
} from '../../helpers/requester';
import styles from './UploadPage.module.css';
import 'react-image-crop/dist/ReactCrop.css';
import {SliderSettings} from './SliderSettings';

const acceptedFiles = ['video/mp4', 'video/mov', 'video/quicktime'];

export function UploadPage() {
  const [isFetching, setIsFetching] = useState(false);
  const [crop, setCrop] = useState({
    unit: '%',
    x: 10,
    y: 10,
    width: 20,
    height: 20,
  });
  const [result, setResult] = useState(null);
  const [file, setFile] = useState('');
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  // default values
  const [sliderParams, setSliderParams] = useState({
    hologram_statistic_threshold_1: 1,
    hologram_statistic_threshold_2: 2,
    hologram_statistic_threshold_3: 3,
    hologram_statistic_threshold_4: 4,
    hologram_statistic_threshold_5: 5,
    hologram_statistic_threshold_6: 6,
    hologram_statistic_threshold_7: 7,
    hologram_statistic_threshold_8: 12,
  });

  const sendVideo = useCallback(async videoFile => {
    const formData = new FormData();

    formData.append('video', videoFile);
    setIsFetching(true);
    const {data, isError} = await postVideoRequest(formData);

    if (isError) {
      setIsFetching(false);
      return;
    }

    const fr = new FileReader();
    fr.onload = () => {
      setPhoto(fr.result);
      setIsFetching(false);
    };
    fr.readAsDataURL(data);
  }, []);

  const [region, setRegion] = useState(null);

  const getHolo = useCallback(async uuid => {
    const {data, isError, isInProgress} = await getHoloByUuid(uuid);

    setMessage(data.message);

    if (isInProgress) {
      setTimeout(() => {
        getHolo(uuid);
      }, 5000);
    } else {
      if (!isError) {
        const {aligned_cropped_document_video, hologram_regions} = data;

        setResult(aligned_cropped_document_video);
        setRegion(hologram_regions[0]);
        setIsFetching(false);
      }

      setIsFetching(false);
    }
  }, []);

  const processPhoto = useCallback(async () => {
    setIsFetching(true);
    const formData = new FormData();

    formData.append('video', file);
    Object.keys(sliderParams).forEach(name => {
      formData.append(name, sliderParams[name]);
    });

    formData.append(
      'hologram_regions',
      JSON.stringify([
        {
          x: crop.x / 100,
          y: crop.y / 100,
          width: crop.width / 100,
          height: crop.height / 100,
          type: 'myType',
        },
      ])
    );

    const {data: uuid, isError} = await getUuidForHolo(formData);

    if (isError) {
      setIsFetching(false);
      return;
    }

    getHolo(uuid);
  }, [crop, file, sliderParams, getHolo]);

  const onFileChange = useCallback(
    files => {
      if (files[0]) {
        setFile(files[0]);

        sendVideo(files[0]);
      }
    },
    [sendVideo]
  );

  const [template, setTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');

  useEffect(async () => {
    if (template) {
      setIsFetching(true);
      const {data, isError} = await getSelectedTemplate(template);
      setIsFetching(false);

      if (isError) {
        return;
      }

      const {x, y, width, height, ...params} = data;
      setCrop(crp => ({
        ...crp,
        x: x * 100,
        y: y * 100,
        height: height * 100,
        width: width * 100,
      }));
      setSliderParams(params);
      setTemplateName(template);
    }
  }, [template]);

  useEffect(async () => {
    setIsFetching(true);
    const {data, isError} = await getTemplates();
    setIsFetching(false);

    if (isError) {
      return;
    }
    setTemplates(data);
  }, []);

  const {
    type,
    hologram_gif,
    hologram_statistic_image,
    hologram_detected,
    ...params
  } = region || {};

  return (
    <div className={styles.processDocPage}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div
          style={{
            width: '100%',
            height: '100px',
            display: 'flex',
            padding: '20px',
            alignItems: 'center',
            columnGap: '20px',
          }}
        >
          <FormControl
            variant='outlined'
            style={{minWidth: '150px', height: '55px'}}
          >
            <InputLabel id='tpl_label'>Template</InputLabel>
            <Select
              style={{minWidth: '150px', height: '55px'}}
              labelId='tpl_label'
              value={template}
              onChange={e => {
                setTemplate(e.target.value);
              }}
              label='Template'
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>

              {templates.map(tpl => (
                <MenuItem key={tpl} value={tpl}>
                  {tpl}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='outlined' style={{minWidth: '150px'}}>
            <TextField
              style={{minWidth: '150px', height: '55px'}}
              value={templateName}
              onChange={e => {
                setTemplateName(e.target.value);
              }}
              variant='outlined'
            />
          </FormControl>

          <Button
            variant='outlined'
            type='button'
            onClick={() => {
              saveTemplate({
                id: templateName,
                data: {
                  ...sliderParams,
                  x: crop.x / 100,
                  y: crop.y / 100,
                  width: crop.width / 100,
                  height: crop.height / 100,
                },
              });
            }}
          >
            Save
          </Button>
        </div>
        <div className={styles.dropZoneWrapper}>
          <DropzoneArea
            onChange={onFileChange}
            acceptedFiles={acceptedFiles}
            showPreviews={false}
            filesLimit={1}
            showPreviewsInDropzone={false}
            maxFileSize={100000000}
          />
          {isFetching ? <CircularProgress /> : 'Drop video there'}
        </div>
        <div>
          <ReactCrop
            className={styles.cropper}
            src={photo || '/logo512.png'}
            crop={crop}
            // keepSelection
            imageStyle={{width: '100%'}}
            locked={false}
            onChange={(pxCrop, percentCrop) => {
              setCrop(percentCrop);
            }}
            onImageLoaded={() => false}
          />
        </div>
      </div>
      <div style={{textAlign: 'left', overflow: 'auto'}}>
        <div className={styles.slidersBox}>
          <SliderSettings
            step={0.1}
            min={0}
            max={100}
            value={sliderParams}
            name='hologram_statistic_threshold_1'
            setValue={setSliderParams}
          />
          <SliderSettings
            step={2}
            min={0}
            max={50}
            value={sliderParams}
            name='hologram_statistic_threshold_2'
            setValue={setSliderParams}
          />
          <SliderSettings
            value={sliderParams}
            name='hologram_statistic_threshold_3'
            setValue={setSliderParams}
          />
          <SliderSettings
            value={sliderParams}
            name='hologram_statistic_threshold_4'
            setValue={setSliderParams}
          />
        </div>
        <div className={styles.slidersBox}>
          <SliderSettings
            value={sliderParams}
            name='hologram_statistic_threshold_5'
            setValue={setSliderParams}
          />
          <SliderSettings
            value={sliderParams}
            name='hologram_statistic_threshold_6'
            setValue={setSliderParams}
          />
          {/* default slider with step=0.1 min=0 max=100 */}
          <SliderSettings
            value={sliderParams}
            name='hologram_statistic_threshold_7'
            setValue={setSliderParams}
          />

          {/* custom slider with step=2 min=10 max=80 */}
          <SliderSettings
            min={10}
            max={80}
            step={2}
            value={sliderParams}
            name='hologram_statistic_threshold_8'
            setValue={setSliderParams}
          />
        </div>
        <Button type='button' variant='outlined' onClick={processPhoto}>
          Extract hologram
        </Button>
        <div style={{display: 'flex', height: 'calc(100% - 300px)'}}>
          {message && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                overflow: 'auto',
                height: '100%',
                rowGap: '20px',
                fontSize: '20px',
                whiteSpace: 'pre',
              }}
            >
              {message}
            </div>
          )}
          {region && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                overflow: 'auto',
                height: '100%',
                rowGap: '20px',
                fontSize: '20px',
              }}
            >
              {/* <div>
              Hologram was detected:{' '}
              <span
                style={{color: region?.hologram_detected ? 'green' : 'red'}}
              >
                <b>{hologram_detected.toString()}</b>
              </span>
            </div> */}

              {Object.keys(params).map(key => {
                return (
                  <div key={key}>
                    {key}:{' '}
                    <span
                      style={{
                        color:
                          params[key] >=
                          sliderParams[key.replace('parameter', 'threshold')]
                            ? 'green'
                            : 'red',
                      }}
                    >
                      <b>{params[key]}</b>
                    </span>
                  </div>
                );
              })}

              <img
                style={{width: 'max-content'}}
                alt='hologram_statistic_image'
                src={`data:text/html;base64,${hologram_statistic_image}`}
              />
              <img
                style={{width: 'max-content'}}
                alt='hologram_gif'
                src={`data:text/html;base64,${hologram_gif}`}
              />

              <Button
                variant='outlined'
                type='button'
                onClick={() => {
                  const downloadLink = document.createElement('a');

                  downloadLink.href = `data:video/mp4;base64,${result}`;
                  downloadLink.target = '_self';
                  downloadLink.download = 'capturedVideo.mp4';

                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }}
              >
                Download Video
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
