import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DropzoneArea} from 'material-ui-dropzone';
import {SplitPane} from 'react-multi-split-pane';

import {CircularProgress} from '@material-ui/core';
import {getCurrentUser} from '../../slices/userSlice';
import {getImage} from '../../helpers/requester';
import {ProccessDocPanel} from '../ProccessDocPanel/ProccessDocPanel';
import {DocInfoPanel} from '../DocInfoPanel/DocInfoPanel';
import styles from './ProcessDocPage.module.css';

const defaultSizes = JSON.parse(
  localStorage.getItem('processDocSplitSizes')
) || [6, 2, 2];
const defaultHorizontalSizes = JSON.parse(
  localStorage.getItem('processDocHorizontalSplitSizes')
) || [1, 1];
const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp'];

const convertBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
};

export function ProcessDocPage() {
  const {isExtractPanelVisible, isProcessPanelVisible} =
    useSelector(getCurrentUser);

  const [isFetching, setIsFetching] = useState(false);
  const [frontImg, setFrontImg] = useState('');
  const [backImg, setBackImg] = useState('');
  const [fileName, setFileName] = useState('');
  const [backFileName, setBackFileName] = useState('');

  const postPhoto = useCallback(async (file, isFrontImg) => {
    const base64 = await convertBase64(file);
    window.history.pushState({}, document.title, window.location.pathname);

    if (isFrontImg) {
      setFrontImg(base64);
      setFileName(file.name.replace(/\.\w+$/, ''));
    } else {
      setBackImg(base64);
      setBackFileName(file.name.replace(/\.\w+$/, ''));
    }
  }, []);

  const fetchRawImage = async (id, isBack) => {
    setIsFetching(true);
    const {data} = await getImage(id);

    if (data) {
      return new Promise(resolve => {
        const fr = new FileReader();
        fr.onload = () => {
          if (isBack) {
            setBackImg(fr.result);
            setBackFileName(`${id}.jpg`);
          } else {
            setFrontImg(fr.result);
            setFileName(`${id}.jpg`);
          }

          setIsFetching(false);
          resolve(fr.result);
        };
        fr.readAsDataURL(data);
      });
    }
    setIsFetching(false);

    return '';
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const imageId = url.searchParams.get('imageid');
    const imageIdBack = url.searchParams.get('imageid_back');

    if (imageId) {
      fetchRawImage(imageId);
    }
    if (imageIdBack) {
      fetchRawImage(imageIdBack, true);
    }
  }, []);

  const onFrontFileChange = useCallback(
    files => {
      files[0] && postPhoto(files[0], true);
    },
    [postPhoto]
  );

  const onBackFileChange = useCallback(
    files => {
      files[0] && postPhoto(files[0]);
    },
    [postPhoto]
  );

  return (
    <div className={styles.processDocPage}>
      <SplitPane
        split='vertical'
        defaultSizes={defaultSizes}
        onDragFinished={size =>
          localStorage.setItem('processDocSplitSizes', JSON.stringify(size))
        }
      >
        <SplitPane
          split='horizontal'
          defaultSizes={defaultHorizontalSizes}
          onDragFinished={size =>
            localStorage.setItem(
              'processDocHorizontalSplitSizes',
              JSON.stringify(size)
            )
          }
        >
          <div className={styles.dropZoneWrapper}>
            <DropzoneArea
              onChange={onFrontFileChange}
              acceptedFiles={acceptedFiles}
              showPreviews={false}
              filesLimit={1}
              showPreviewsInDropzone={false}
              maxFileSize={100000000}
            />
            {isFetching ? (
              <CircularProgress />
            ) : (
              (frontImg && (
                <>
                  <img
                    className={styles.selectedImage}
                    src={frontImg}
                    alt='doc'
                  />
                  <div
                    role='presentation'
                    onClick={() => {
                      setFrontImg('');
                    }}
                    className={styles.dropdownReset}
                  >
                    Reset
                  </div>
                </>
              )) ||
              'Drop front page there'
            )}
          </div>

          <div className={styles.dropZoneWrapper}>
            <DropzoneArea
              onChange={onBackFileChange}
              acceptedFiles={acceptedFiles}
              showPreviews={false}
              filesLimit={1}
              showPreviewsInDropzone={false}
              maxFileSize={100000000}
            />
            {isFetching ? (
              <CircularProgress />
            ) : (
              (backImg && (
                <>
                  <div
                    role='presentation'
                    onClick={() => {
                      setBackImg('');
                    }}
                    className={styles.dropdownReset}
                  >
                    Reset
                  </div>
                  <img
                    className={styles.selectedImage}
                    src={backImg}
                    alt='doc'
                  />
                </>
              )) ||
              'Drop back page there'
            )}
          </div>
        </SplitPane>
        {isExtractPanelVisible && (
          <SplitPane
            split='horizontal'
            defaultSizes={defaultHorizontalSizes}
            onDragFinished={size =>
              localStorage.setItem(
                'processDocHorizontalSplitSizes',
                JSON.stringify(size)
              )
            }
          >
            <DocInfoPanel fileName={fileName} image={frontImg} />

            <DocInfoPanel fileName={backFileName} image={backImg} />
          </SplitPane>
        )}
        {isProcessPanelVisible && (
          <ProccessDocPanel
            isAutoProcessDisabled
            fileName={fileName}
            isPage
            image={frontImg}
            backImage={backImg}
          />
        )}
      </SplitPane>
    </div>
  );
}
