import React, {useState} from 'react';

import {
  Select,
  Button,
  MenuItem,
  Paper,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import styles from './EditRegionCodeModal.module.css';

export function EditRegionCodeModal({
  handleEditRegion,
  fieldName,
  fieldValue,
  codeList,
  handleClose,
}) {
  const [value, setValue] = useState(fieldValue || '');

  const handleUpdateRegions = () => {
    handleEditRegion({[fieldName]: value});
    handleClose();
  };

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id={`Select ${fieldName} code`}>
            Select {fieldName} code
          </InputLabel>
          <Select
            labelId={`Select ${fieldName} code`}
            label={`Select ${fieldName} code`}
            fullWidth
            value={value || ''}
            onChange={e => {
              setValue(e.target.value);
              // temp
              handleEditRegion({[fieldName]: e.target.value});
              handleClose();
            }}
          >
            {codeList.map(({id, name, desc}) => (
              <MenuItem key={id} value={id}>
                <span>
                  {name}({id})
                  <br />
                  <span className={styles.itemDesc}>Desc:{desc}</span>
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={styles.buttonsWrapper}>
          <Button variant='contained' onClick={handleUpdateRegions}>
            Save
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>
    </div>
  );
}
