/* eslint-disable jsx-a11y/media-has-caption */
import React, {useCallback, useState} from 'react';
import {DropzoneArea} from 'material-ui-dropzone';

import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import {postVideoRequest, saveHoloData} from '../../helpers/requester';
import styles from './HoloMarkupPage.module.css';
import 'react-image-crop/dist/ReactCrop.css';
import {SliderSettings} from '../UploadPage/SliderSettings';
import {Cropper} from '../ImageCropper/ImageCropper';

const acceptedFiles = ['video/mp4', 'video/mov', 'video/quicktime'];

const DEFAULT_HOLOGRAM_STATISTIC_PARAM_VALUE = 3;
const DEFAULT_REGION_NAME = 'newRegion';

export function HoloMarkupPage() {
  const [file, setFile] = useState('');
  const [photo, setPhoto] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const sendVideo = useCallback(async videoFile => {
    const formData = new FormData();

    formData.append('video', videoFile);
    setIsFetching(true);
    const {data, isError} = await postVideoRequest(formData);

    if (isError) {
      setIsFetching(false);
      return;
    }

    const fr = new FileReader();
    fr.onload = () => {
      setPhoto(fr.result);
      setIsFetching(false);
    };
    fr.readAsDataURL(data);
  }, []);

  const getHolo = useCallback(async () => {
    const payload = {
      template_id: file?.name?.split('___')[1],
      holograms: regions.map(
        ({id: name, height, width, x, y, hologram_statistic_threshold}) => ({
          name,
          height,
          width,
          x,
          y,
          hologram_statistic_threshold,
        })
      ),
    };

    const {data, isError} = await saveHoloData(payload);

    if (isError) {
      setError(data);
    } else {
      setMessage(data);
    }

    setIsFetching(false);
  }, [file.name, regions]);

  const onFileChange = useCallback(
    files => {
      if (files[0]) {
        setFile(files[0]);

        sendVideo(files[0]);
      }
    },
    [sendVideo]
  );

  const updateSelectedRegion = updatedFields => {
    const newRegions = regions.map(reg => {
      if (reg.id === selectedRegion?.id) {
        const newRegion = {
          ...reg,
          ...updatedFields,
        };
        setSelectedRegion(newRegion);
        return newRegion;
      }
      return reg;
    });

    setRegions(newRegions);
  };

  const addRegion = () => {
    const newRegion = {
      id: DEFAULT_REGION_NAME,
      x: 0.2,
      y: 0.2,
      width: 0.3,
      height: 0.3,
      type: {name: DEFAULT_REGION_NAME},
      hologram_statistic_threshold: DEFAULT_HOLOGRAM_STATISTIC_PARAM_VALUE,
    };
    setRegions([...regions, newRegion]);
    setSelectedRegion(newRegion);
  };

  const removeRegion = () => {
    setRegions(regions.filter(({id}) => id !== selectedRegion?.id));
    setSelectedRegion('');
  };

  const onChangeSelectedRegion = e => {
    setSelectedRegion(regions.find(({id}) => id === e.target.value));
  };

  const onChangeSelectedRegionName = e => {
    const updatedFields = {
      id: e.target.value,
      type: {name: e.target.value},
    };

    updateSelectedRegion(updatedFields);
  };

  return (
    <div className={styles.processDocPage}>
      <div className={styles.videoBlockWrapper}>
        <div className={styles.editRegionsPanel}>
          <FormControl
            variant='outlined'
            style={{minWidth: '150px', height: '55px'}}
          >
            <InputLabel id='tpl_label'>Region</InputLabel>
            <Select
              style={{minWidth: '150px', height: '55px'}}
              labelId='tpl_label'
              value={selectedRegion?.id || ''}
              onChange={onChangeSelectedRegion}
              label='Template'
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>

              {regions.map(({id}) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='outlined' style={{minWidth: '150px'}}>
            <TextField
              style={{minWidth: '150px', height: '55px'}}
              value={selectedRegion?.id || ''}
              onChange={onChangeSelectedRegionName}
              variant='outlined'
            />
          </FormControl>

          <Button
            variant='outlined'
            type='button'
            disabled={regions.find(({id}) => id === DEFAULT_REGION_NAME)}
            onClick={addRegion}
          >
            +
          </Button>

          <Button variant='outlined' type='button' onClick={removeRegion}>
            -
          </Button>
        </div>
        <div className={styles.dropZoneWrapper}>
          <DropzoneArea
            onChange={onFileChange}
            acceptedFiles={acceptedFiles}
            showPreviews={false}
            filesLimit={1}
            showPreviewsInDropzone={false}
            maxFileSize={100000000}
          />
          {isFetching ? <CircularProgress /> : 'Drop video there'}
        </div>

        {file?.name?.split('___')[1]}

        <div
          style={{
            width: '90%',
            overflow: 'hidden',
            height: '50%',
          }}
        >
          <Cropper
            isFetching={false}
            isImageValid
            key={file?.name}
            image={photo || '/logo512.png'}
            regions={regions || []}
            selectedRegion={regions.find(
              ({id}) => id && id === selectedRegion?.id
            )}
            onRegionChange={updateSelectedRegion}
            selectRegion={setSelectedRegion}
          />
        </div>
      </div>
      <div style={{textAlign: 'left', overflow: 'auto'}}>
        <div className={styles.slidersBox}>
          <SliderSettings
            step={0.1}
            min={0}
            max={100}
            value={{
              hologram_statistic_threshold:
                selectedRegion?.hologram_statistic_threshold || 0,
            }}
            name='hologram_statistic_threshold'
            setValue={updateSelectedRegion}
          />
        </div>
        <Button variant='outlined' type='button' onClick={getHolo}>
          Upload
        </Button>

        {(message || error) && (
          <div
            className={styles.resultMessage}
            style={{
              color: error ? 'red' : 'green',
            }}
          >
            {message || error}
          </div>
        )}
      </div>
    </div>
  );
}
