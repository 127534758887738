import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@material-ui/core';
import {ConfirmModal} from '../ConfirmModal/ConfirmModal';
import {
  loadDocumentTypes,
  loadTemplatesForCountry,
  getTemplate,
  loadCountries,
} from '../../../slices/templateSlice';
import {
  createTemplate,
  updateTemplate,
  addBackPage,
} from '../../../helpers/requester';
import styles from './LoginPage.module.css';

export function AddTemplate({
  template = {
    name: '',
    desc: '',
    type: '',
    country: '',
    online: true,
  },
  handleClose,
}) {
  const {
    countries,
    selectedCountry,
    selectedTemplate,
    documentTypes,
    dictionaries,
  } = useSelector(getTemplate);
  const dispatch = useDispatch();

  const [name, setName] = useState(template.name);
  const [desc, setDesc] = useState(template.desc);
  const [type, setType] = useState(template.type.id);
  const [country, setCountry] = useState(template.country.id);
  const [isolate, setIsolate] = useState(template.isolate);
  const [online, setOnline] = useState(template.online);
  const [addBackConfirmModalOpen, setAddBackConfirmModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadCountries());
    dispatch(loadDocumentTypes());
  }, [dispatch]);

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <div className='modal-title'>
          {template.id ? 'Update template' : 'Create template'}
        </div>
        <TextField
          label='name'
          variant='outlined'
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          fullWidth
        />
        <TextField
          label='desc'
          variant='outlined'
          fullWidth
          value={desc}
          onChange={e => {
            setDesc(e.target.value);
          }}
        />
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>Select country</InputLabel>
          <Select
            labelId='select-country-label'
            label='Select country'
            value={country}
            onChange={e => {
              setCountry(e.target.value);
            }}
          >
            {countries.map(({id, name: countryName}) => (
              <MenuItem key={id} value={id}>
                {countryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='select-country-label'>Select docType</InputLabel>
          <Select
            fullWidth
            labelId='select-country-label'
            label='Select country'
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
          >
            {documentTypes.map(({id, name: docTypeName}) => (
              <MenuItem key={id} value={id}>
                {docTypeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='Select isolate'>Select isolate</InputLabel>
          <Select
            labelId='Select isolate'
            label='Select isolate'
            fullWidth
            value={isolate || ''}
            onChange={e => {
              setIsolate(e.target.value);
            }}
          >
            {dictionaries.isolate.map(([value, dictName]) => (
              <MenuItem key={value} value={value}>
                {dictName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{display: 'flex', width: '100%'}}>
          <FormControl fullWidth variant='outlined'>
            <FormControlLabel
              fullWidth
              control={
                <Checkbox
                  checked={online}
                  onChange={e => {
                    setOnline(e.target.checked);
                  }}
                  name='online'
                  color='primary'
                />
              }
              label='Online'
            />
          </FormControl>

          <Button
            variant='outlined'
            fullWidth
            disabled={!selectedTemplate?.id || selectedTemplate?.back}
            onClick={() => {
              setAddBackConfirmModalOpen(true);
            }}
          >
            Add Back Page
          </Button>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            variant='contained'
            onClick={async () => {
              const action = template.id ? updateTemplate : createTemplate;
              const {isError} = await action({
                ...template,
                name,
                desc,
                type: documentTypes.find(({id}) => id === type).name,
                country: countries.find(({id}) => id === country).name,
                online,
                isolate,
              });

              if (!isError) {
                selectedCountry &&
                  (await dispatch(loadTemplatesForCountry(selectedCountry)));
                toast.success(
                  template.id ? 'Template updated' : 'Template created'
                );
                handleClose();
              }
            }}
          >
            {template.id ? 'Update template' : 'Create template'}
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>

      <ConfirmModal
        open={addBackConfirmModalOpen}
        message='Are yo sure you want to add back page?'
        handleClose={() => {
          setAddBackConfirmModalOpen(false);
        }}
        handleConfirm={async () => {
          const {isError} = await addBackPage(selectedTemplate?.id);

          if (!isError) {
            selectedCountry &&
              (await dispatch(loadTemplatesForCountry(selectedCountry)));
            toast.success('Back page added');
            setAddBackConfirmModalOpen(false);
          }
        }}
      />
    </div>
  );
}
