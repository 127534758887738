import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {ConfirmModal} from '../modals/ConfirmModal/ConfirmModal';
import {getTemplate} from '../../slices/templateSlice';

import {
  getImageById,
  proccessDocRequest,
  proccessDocExtRequest,
  getCode,
} from '../../helpers/requester';
import styles from './ProccessDocPanel.module.css';
import {RegionsList} from './RegionsList';

export function ProccessDocPanel({
  image,
  backImage,
  isPage,
  fileName,
  isAutoProcessDisabled,
}) {
  const {
    regionsChanged,
    selectedCountry,
    countries,
    selectedTemplate,
    selectedPage,
    selectedImageId,
  } = useSelector(getTemplate);

  const [regions, setRegions] = useState([]);
  const [docData, setDocData] = useState({});
  const [allowOffline, setAllowOffline] = useState(true);

  const [base64meta, setBase64Meta] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rawImage, setRawImage] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const [code, setCode] = useState([]);

  const proccessDoc = useCallback(
    async (base64Image, base64BackImage) => {
      setIsFetching(true);
      setDocData({});
      setRegions([]);

      const {data: codeFetched} = await getCode();
      setCode(codeFetched);

      const splitCropped = base64Image.split(',');
      const backSplitCropped = base64BackImage?.split(',');

      const {data} =
        base64Image && base64BackImage
          ? await proccessDocExtRequest({
              imageb64: splitCropped[1],
              image_extb64: backSplitCropped[1],
              allowOffline,
              source: isPage ? 'uitesting' : 'uitemplating',
            })
          : await proccessDocRequest({
              imageb64: splitCropped[1],
              allowOffline,
              source: isPage ? 'uitesting' : 'uitemplating',
            });

      try {
        const {regions: fetchedRegions, ...restData} = data;

        setDocData(restData);
        setRegions(fetchedRegions || []);
      } catch (e) {
        setDocData({});
        setRegions([]);
      } finally {
        setBase64Meta(splitCropped[0]);
        setIsFetching(false);
      }
    },
    [allowOffline, isPage]
  );

  const fetchRawImage = async () => {
    const {data} = await getImageById(selectedImageId, 'RAW');

    if (data) {
      return new Promise(resolve => {
        const fr = new FileReader();
        fr.onload = () => {
          setRawImage(fr.result);
          resolve(fr.result);
        };
        fr.readAsDataURL(data);
      });
    }

    return '';
  };

  const getRawAndProcess = async () => {
    if (backImage) {
      image ? proccessDoc(image, backImage) : proccessDoc(backImage);
    } else {
      const rawImg = isPage && image ? image : await fetchRawImage();
      proccessDoc(rawImg);
    }
  };

  useEffect(() => {
    if (!isAutoProcessDisabled) {
      if (isPage && (image || backImage)) {
        image && backImage
          ? proccessDoc(image, backImage)
          : proccessDoc(image || backImage);
      } else {
        setBase64Meta('');
        setRegions([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPage, image, backImage]);

  const getFileName = () =>
    fileName ||
    `${countries.find(({id}) => id === selectedCountry)?.name}.${
      selectedTemplate?.name
    }.${selectedPage}.${selectedImageId}`;

  const getJsonData = () =>
    `data:text/json;charset=utf-8,${JSON.stringify(
      {
        textregions: regions.reduce((acc, {name, data}) => {
          acc[name] = data;
          return acc;
        }, {}),
      },
      null,
      '\t'
    )}`;

  return (
    <div className={styles.proccessDocPanel}>
      <div className={styles.btnsRow}>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              onChange={e => {
                setAllowOffline(e.target.checked);
              }}
              checked={allowOffline}
            />
          }
          label='Allow offline'
        />

        <Button
          className={styles.loadButton}
          disabled={!image && !backImage}
          variant='outlined'
          onClick={() => {
            regionsChanged ? setConfirmModalOpen(true) : getRawAndProcess();
          }}
          style={{marginRight: 'auto'}}
        >
          Process Doc
        </Button>

        {regions.length > 0 && (
          <>
            <a
              className={styles.loadButton}
              href={getJsonData()}
              download={`${getFileName()}.json`}
            >
              GET JSON
            </a>
            <a
              className={styles.loadButton}
              href={rawImage || image || backImage}
              download={`${getFileName()}.jpg`}
            >
              GET RAW IMAGE
            </a>
          </>
        )}
      </div>

      {isFetching ? (
        <CircularProgress />
      ) : (
        base64meta && (
          <RegionsList
            docData={docData}
            regions={regions}
            selectedTemplate={selectedTemplate}
            selectedPage={selectedPage}
            code={code}
            base64meta={base64meta}
          />
        )
      )}

      <ConfirmModal
        open={confirmModalOpen}
        message='You have unsaved changes. Are you sure you want to continue?'
        handleClose={() => {
          setConfirmModalOpen(false);
        }}
        handleConfirm={getRawAndProcess}
      />
    </div>
  );
}
