import React from 'react';
import {ImgEditorWrapper} from './components/ImgEditorWrapper/Editor';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'space-between',
      }}
    >
      <ImgEditorWrapper />
    </div>
  );
}

export default App;
