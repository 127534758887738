import React, {useEffect, useState, useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {selectTemplate, selectCountry} from '../../slices/templateSlice';

import {getOnlineTemplates} from '../../helpers/requester';
import styles from './OnlineTemplatesPage.module.css';

const columns = [
  {
    id: 'id',
    label: 'id',
  },
  {
    id: 'country',
    label: 'country',
    format: ({name}) => name,
  },
  {
    id: 'name',
    label: 'name',
  },
  {
    id: 'type',
    label: 'type',
    format: ({name}) => name,
  },
  {
    id: 'desc',
    label: 'desc',
  },
];

export function OnlineTemplatesPage({history}) {
  const dispatch = useDispatch();

  const [templates, setTemplates] = useState([]);

  const getTemplates = useCallback(async () => {
    const {data} = await getOnlineTemplates();
    setTemplates(data || []);
  }, []);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  return (
    <div className={styles.reportsPage}>
      <div className={styles.filtersWrapper}>
        <Button variant='outlined' onClick={getTemplates}>
          Get templates
        </Button>
      </div>

      <TableContainer className='reports-table' sx={{maxHeight: 440}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(row => {
              return (
                <TableRow
                  onClick={() => {
                    history.push('/app');
                    dispatch(selectCountry(row.country.id));
                    dispatch(selectTemplate(row));
                  }}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.date}
                >
                  {columns.map(column => {
                    const value = row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        <>{column.format ? column.format(value) : value}</>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
