import * as axios from 'axios';
import {toast} from 'react-toastify';

export const BASE_URL =
  window?.GLOBAL_ENV?.REACT_APP_BASE_URL ||
  'https://docdb-api-test.otcdesk.ch/api/v1';
// 'https://docdb-api-test.otcdesk.ch/api/v1';

export const getHeader = (header = {}) => {
  const newHeader = {...header};
  const token = localStorage.getItem('token');
  const key = localStorage.getItem('key');

  token
    ? (newHeader.Authorization = `Bearer ${token}`)
    : (newHeader['X-API-KEY'] = key);

  return newHeader;
};

const createMethod = (
  baseUrl,
  url,
  data,
  headers,
  method,
  responseType,
  cancelTokenSource
) =>
  axios.create({baseURL: baseUrl}).request({
    url,
    data,
    method,
    responseType,
    headers: getHeader(headers),

    cancelToken: cancelTokenSource?.token,
  });

const GET = (baseUrl, url, data, headers, responseType, cancelTokenSource) =>
  createMethod(
    baseUrl,
    url,
    data,
    headers,
    'GET',
    responseType,
    cancelTokenSource
  );
const POST = (baseUrl, url, data, headers, responseType) => {
  return createMethod(baseUrl, url, data, headers, 'POST', responseType);
};

// const PUT = (baseUrl, url, data, headers) =>
//   createMethod(baseUrl, url, data, headers, 'PUT');
// const DELETE = (baseUrl, url, data, headers) =>
//   createMethod(baseUrl, url, data, headers, 'DELETE');

async function makeCall(
  method,
  baseUrl,
  url,
  data,
  headers = {},
  responseType,
  cancelTokenSource
) {
  try {
    return await method(
      baseUrl,
      url,
      data,
      headers,
      responseType,
      cancelTokenSource
    );
  } catch (e) {
    const {status, data: errorData} = e.response;

    if (status === 406) {
      return {isInProgress: true, data: errorData};
    }

    toast.error(e.message);
    if (status === 400) {
      localStorage.removeItem('key');
      localStorage.removeItem('token');
      window.location.reload();
    }
    if (status === 422) {
      return {data: errorData, status, isError: true};
    }

    return {isError: true, data: errorData};
  }
}

export const loginRequest = payload =>
  makeCall(POST, BASE_URL, `/user/login`, payload);
export const getUserMode = () => makeCall(GET, BASE_URL, `/user/mode`);
export const logoutRequest = () => makeCall(POST, BASE_URL, `/user/logout`);
export const getCountriesRequest = () => makeCall(GET, BASE_URL, `/countries`);
export const getDocumentTypesRequest = () =>
  makeCall(GET, BASE_URL, `/document_types`);
export const getTemplatesForCountryRequest = id =>
  makeCall(GET, BASE_URL, `/templates_for_country?oid=${id}`);
export const getImageById = (id, type, cancelTokenSource) =>
  makeCall(
    GET,
    BASE_URL,
    `/template_image?oid=${id}&type=${type}`,
    {},
    {},
    'blob',
    cancelTokenSource
  );
export const getImageRegionTypes = () =>
  makeCall(GET, BASE_URL, `/image_region_types`);
export const proccessDocRequest = ({allowOffline, source, ...payload}) =>
  makeCall(
    POST,
    BASE_URL,
    `/gpregular/process_doc?allow_offline=${
      allowOffline ? 'True' : 'False'
    }&source=${source || 'uitesting'}`,
    payload
  );
export const proccessDocExtRequest = ({allowOffline, source, ...payload}) =>
  makeCall(
    POST,
    BASE_URL,
    `/gpregular/process_doc_ext?allow_offline=${
      allowOffline ? 'True' : 'False'
    }&source=${source || 'uitesting'}`,
    payload
  );
export const DocInfoRequest = payload =>
  makeCall(POST, BASE_URL, `/document_extraction/image_info`, payload);
export const extractDoc = ({type = 'SIDESDEBUG', image}) =>
  makeCall(
    POST,
    BASE_URL,
    `/document_extraction/extract_document?type=${type}`,
    {imageb64: image},
    {},
    'blob'
  );

export const updateTemplate = payload =>
  makeCall(POST, BASE_URL, `/update_template`, payload);
export const createTemplate = payload =>
  makeCall(POST, BASE_URL, `/create_template`, payload);
export const deleteTemplate = oid =>
  makeCall(POST, BASE_URL, `/delete_template?oid=${oid}`);
export const addBackPage = oid =>
  makeCall(POST, BASE_URL, `/add_back_to_template?oid=${oid}`);
export const updateTemplatePage = page =>
  makeCall(POST, BASE_URL, `/update_template_page`, page);
export const updatePageRegions = ({oid, page}) =>
  makeCall(POST, BASE_URL, `/update_page_regions?oid=${oid}`, page);
export const addTemplateImage = ({pageId, imageb64}) =>
  makeCall(POST, BASE_URL, `/add_template_image?oid=${pageId}`, {imageb64});
export const deleteTemplateImage = oid =>
  makeCall(POST, BASE_URL, `/delete_template_image?oid=${oid}`);
export const getDict = () => makeCall(GET, BASE_URL, `/dictionaries`);
export const getCode = () => makeCall(GET, BASE_URL, `/list_code`);
export const createCode = code =>
  makeCall(POST, BASE_URL, `/create_code`, code); // {code,desc,name,type}
export const updateCode = code =>
  makeCall(POST, BASE_URL, `/update_code`, code);
export const deleteCode = oid =>
  makeCall(POST, BASE_URL, `/delete_code?oid=${oid}`);

export const getLanguages = () => makeCall(GET, BASE_URL, '/get_ocr_languages');

export const setTemplateImageProps = payload =>
  makeCall(POST, BASE_URL, `/set_template_image_props`, payload);

export const getReports = ({page, pageSize, ...data}) =>
  makeCall(
    POST,
    BASE_URL,
    `/reporting/reports?page=${page}&pageSize=${pageSize}`,
    data
  );
export const getReportsCount = data =>
  makeCall(POST, BASE_URL, `/reporting/reports_count`, data);
export const getTypes = typename =>
  makeCall(GET, BASE_URL, `/reporting/gettypes?typename=${typename}`);
export const getImage = oid =>
  makeCall(
    GET,
    BASE_URL,
    `/reporting/get_image?imageid=${oid}`,
    {},
    {},
    'blob'
  );
export const getOnlineTemplates = () =>
  makeCall(GET, BASE_URL, `/online_templates`);
export const getReportsByType = ({typename, ...data}) =>
  makeCall(
    POST,
    BASE_URL,
    `/reporting/reports_count_by_type?typename=${typename}`,
    data
  );

export const getReportsByDate = data =>
  makeCall(POST, BASE_URL, `/reporting/reports_by_dates`, data);

export const postVideoRequest = data =>
  makeCall(
    POST,
    BASE_URL,
    `/hologram/extract_frame_from_video`,
    data,
    {},
    'blob'
  );

export const getUuidForHolo = data =>
  makeCall(
    POST,
    BASE_URL,
    `/hologram/get_uuid_to_detect_hologram_from_video`,
    data
  );

export const getHoloByUuid = data =>
  makeCall(POST, BASE_URL, `/hologram/get_hologram_output_using_uuid`, data);

export const getTemplates = () =>
  makeCall(GET, BASE_URL, `/hologram/get_documents_with_hologram`);

export const getSelectedTemplate = data =>
  makeCall(
    POST,
    BASE_URL,
    `/hologram/get_data_about_documents_with_hologram`,
    data
  );

export const saveTemplate = data =>
  makeCall(
    POST,
    BASE_URL,
    `/hologram/save_data_about_documents_with_hologram`,
    data
  );

export const saveHoloData = data =>
  makeCall(POST, BASE_URL, `/hologram/save_new_hologram_data`, data);
