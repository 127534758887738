import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {toast} from 'react-toastify';
import {TextField, Button, Paper} from '@material-ui/core';
import {
  loadTemplatesForCountry,
  getTemplate,
} from '../../../slices/templateSlice';
import {updateTemplatePage} from '../../../helpers/requester';
import styles from './LoginPage.module.css';

export function EditPage({handleClose}) {
  const {
    selectedCountry,
    selectedPage: selectedPageKey,
    selectedTemplate,
  } = useSelector(getTemplate);
  const selectedPage = selectedTemplate[selectedPageKey];

  const dispatch = useDispatch();

  const [searchId, setSearchId] = useState(selectedPage.searchId);
  const [name, setName] = useState(selectedPage.name || '');

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <div className='modal-title'>Edit page</div>
        <TextField
          label='Name'
          variant='outlined'
          fullWidth
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <TextField
          label='Search Id'
          variant='outlined'
          fullWidth
          value={searchId}
          onChange={e => {
            setSearchId(e.target.value);
          }}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            variant='contained'
            onClick={async () => {
              const {isError} = await updateTemplatePage({
                id: selectedPage?.id,
                searchId,
                name,
              });
              if (!isError) {
                toast.success('Template page updated');
                selectedCountry &&
                  (await dispatch(loadTemplatesForCountry(selectedCountry)));
                handleClose();
              }
            }}
          >
            Update Page
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>
    </div>
  );
}
