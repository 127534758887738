import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getTypes,
  getReports,
  getReportsCount,
  getReportsByType,
  getReportsByDate,
} from '../helpers/requester';

const initialState = {
  dictionary: {
    countries: [],
    sources: [],
    userdata: [],
    types: [],
    errors: [],
    templates: [],
  },
  filters: {
    fromdate: moment().add(-7, 'd').toString(),
    todate: moment().toString(),
    countries: [],
    sources: [],
    userdata: [],
    types: [],
    errors: [],
    templates: [],
    status: '',
    hint: '',
    mrz: '',
    complain: '',
    scanid: '',
    typename: '',
  },
  reports: [],
  reportsCount: 0,
  reportsByType: [],
  reportsByDate: [],
  reportsStats: [],
  selectedTab: 0,
  page: 0,
  pageSize: 500,
  isFetching: false,
  imageModalOpen: false,
};

export const getDictionaryRequest = createAsyncThunk(
  'reports/getDictionary',
  async () => {
    const typesToFetch = [
      'countries',
      'types',
      'sources',
      'userdata',
      'errors',
      'templates',
    ];

    const promises = typesToFetch.map(async type => {
      const {data, isError} = await getTypes(type);

      return {[type]: isError ? [] : data};
    });

    const data = await Promise.all(promises);
    const dictObj = data.reduce((acc, obj) => {
      return {...acc, ...obj};
    }, {});

    return dictObj;
  }
);

export const getReportsRequest = createAsyncThunk(
  'reports/getReports',
  async filters => {
    const response = await getReports(filters);

    const {data: reportsCount} = await getReportsCount(filters);

    return {reports: response?.data || [], reportsCount: reportsCount || 0};
  }
);

export const getReportsByTypeRequest = createAsyncThunk(
  'reports/getReportsByType',
  async filters => {
    const response = await getReportsByType(filters);

    return response?.data || [];
  }
);

export const getReportsByDateRequest = createAsyncThunk(
  'reports/getReportsByDate',
  async filters => {
    const {data, isError} = await getReportsByDate(filters);

    if (!isError) {
      return data.map(({name, ...rest}) => {
        return {name: moment(name).format('DD-MM-Y'), ...rest};
      });
    }

    return data || [];
  }
);

export const getReportsStatsRequest = createAsyncThunk(
  'reports/getReportsStats',
  async filters => {
    const {data: totalData, isErrorTotal} = await getReportsByDate({
      ...filters,
      status: null,
    });
    const {data: validData, isErrorValid} = await getReportsByDate({
      ...filters,
      status: true,
    });

    if (!isErrorTotal && !isErrorValid) {
      const totalDict = totalData.reduce((acc, {name, count}) => {
        acc[moment(name).format('DD-MM-Y')] = count;

        return acc;
      }, {});

      return validData.map(({name, count}) => {
        const date = moment(name).format('DD-MM-Y');
        const total = totalDict[date];
        return {
          name: date,
          total,
          valid: count,
          validPercent: +Number((count / total) * 100).toFixed(2),
          invalid: total - count,
        };
      });
    }

    return [];
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,

  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = {...state.filters, ...action.payload};
    },
    setImageModalOpen: (state, action) => {
      state.imageModalOpen = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getDictionaryRequest.fulfilled, (state, action) => {
      state.dictionary = action.payload;
    });
    builder.addCase(getReportsRequest.fulfilled, (state, action) => {
      state.reports = action.payload.reports;
      state.reportsCount = action.payload.reportsCount;
    });
    builder.addCase(getReportsByTypeRequest.fulfilled, (state, action) => {
      state.reportsByType = action.payload;
    });
    builder.addCase(getReportsByDateRequest.fulfilled, (state, action) => {
      state.reportsByDate = action.payload;
    });
    builder.addCase(getReportsStatsRequest.fulfilled, (state, action) => {
      state.reportsStats = action.payload;
    });
  },
});

export const {setPage, setPageSize, setFilters, setImageModalOpen} =
  reportsSlice.actions;

export const getReportsState = state => state.reports;

export default reportsSlice.reducer;
