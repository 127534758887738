import React, {useEffect, useState} from 'react';
import {
  Modal,
  Button,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import styles from './ConfirmModal.module.css';

export function ConfirmModal({
  open,
  message,
  extraConfirmMsg,
  handleClose,
  handleConfirm,
}) {
  const [confirmed, setConfirmed] = useState(!extraConfirmMsg);

  useEffect(() => {
    setConfirmed(!extraConfirmMsg);
  }, [extraConfirmMsg, open]);

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <div className={styles.pageWrapper}>
        <Paper className={styles.form} elevation={3}>
          <Typography>{message}</Typography>

          {extraConfirmMsg && (
            <FormControlLabel
              checked={confirmed}
              control={
                <Checkbox
                  onChange={e => {
                    setConfirmed(e.target.checked);
                  }}
                />
              }
              label={extraConfirmMsg}
              labelPlacement='start'
            />
          )}

          <div className={styles.buttonsWrapper}>
            <Button
              disabled={!confirmed}
              variant='contained'
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
            >
              Confirm
            </Button>

            <Button variant='contained' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Paper>
      </div>
    </Modal>
  );
}
