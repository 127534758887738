import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Tabs, Tab, FormControlLabel, Switch} from '@material-ui/core';

import {
  logoutAsync,
  getCurrentUser,
  setExtractPanelVisible,
  setProcessPanelVisible,
} from '../../slices/userSlice';
import styles from './Header.module.css';

export function Header({location}) {
  const user = useSelector(getCurrentUser);
  const {isExtractPanelVisible, isProcessPanelVisible} = user;
  const dispatch = useDispatch();

  return (
    <div className={styles.selectionWrapper}>
      <div className={styles.linksGroup}>
        <Tabs
          indicatorColor='primary'
          textColor='primary'
          value={location.pathname}
        >
          <Tab
            disabled={!user?.writemode}
            label='Template markup'
            value='/app'
            component={Link}
            to='/app'
          />
          <Tab
            disabled={!user?.writemode}
            label='Edit py code'
            value='/code'
            component={Link}
            to='/code'
          />
          <Tab
            value='/process_doc'
            label='Process Doc'
            component={Link}
            to='/process_doc'
          />
          <Tab
            value='/reports'
            label='Reports'
            component={Link}
            to='/reports'
          />
          <Tab
            value='/online_templates'
            label='Online templates'
            component={Link}
            to='/online_templates'
          />
          <Tab
            value='/upload'
            label='Upload video'
            component={Link}
            to='/upload'
          />
          <Tab
            value='/holo-markup'
            label='holo-markup'
            component={Link}
            to='/holo-markup'
          />
        </Tabs>
      </div>
      <div className={styles.rightAligned}>
        {location.pathname === '/process_doc' && (
          <div className={styles.logout}>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  onChange={e => {
                    dispatch(setExtractPanelVisible(e.target.checked));
                    localStorage.setItem(
                      'isExtractPanelVisible',
                      e.target.checked
                    );
                  }}
                  checked={isExtractPanelVisible}
                />
              }
              label='Show extract panel'
            />
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  onChange={e => {
                    dispatch(setProcessPanelVisible(e.target.checked));
                    localStorage.setItem(
                      'isProcessPanelVisible',
                      e.target.checked
                    );
                  }}
                  checked={isProcessPanelVisible}
                />
              }
              label='Show process panel'
            />
          </div>
        )}

        <div className={styles.logout}>
          <Button
            variant='outlined'
            onClick={() => {
              dispatch(logoutAsync());
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}
