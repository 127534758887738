import React, {useEffect, useState, useCallback} from 'react';

import {toast} from 'react-toastify';
import {
  Modal,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
} from '@material-ui/core';
import {EditCodeModal} from '../modals/AddEditCodeModal/EditCodeModal';
import {getCode, deleteCode} from '../../helpers/requester';
import styles from './CodePage.module.css';

export function CodePage() {
  const [codeList, setCodeList] = useState([]);
  const [selectedCode, setSelectedCode] = useState('');
  const [codeModalOpen, setCodeModalOpen] = useState(false);

  const getCodeAsync = useCallback(async () => {
    let {data} = await getCode();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryId = urlParams.get('code');

    data = data.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    if (queryId) {
      setSelectedCode(data.find(({id}) => +id === +queryId));
    }

    setCodeList(data || []);
  }, []);

  useEffect(() => {
    getCodeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <Paper className={styles.form} elevation={3}>
        <FormControl className='DropDown' fullWidth variant='outlined'>
          <InputLabel id='Select type'>Select code</InputLabel>
          <Select
            labelId='Select type'
            label='Select type'
            fullWidth
            value={selectedCode?.id || ''}
            onChange={e => {
              window.history.pushState(null, null, `?code=${e.target.value}`);
              setSelectedCode(
                codeList.find(({id}) => id === e.target.value) || ''
              );
            }}
          >
            {codeList.map(({name, id, static: staticCode}) => (
              <MenuItem
                style={{backgroundColor: staticCode && 'dimgray'}}
                key={id}
                value={id}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.buttonsWrapper}>
          <Button
            variant='contained'
            onClick={async () => {
              setCodeModalOpen('add');
            }}
          >
            Add Code
          </Button>
          <Button
            variant='contained'
            disabled={!selectedCode}
            onClick={() => {
              setCodeModalOpen('edit');
            }}
          >
            Edit Code
          </Button>
          <Button
            variant='contained'
            disabled={!selectedCode}
            onClick={async () => {
              const {isError} = await deleteCode(selectedCode.id);
              await getCodeAsync();

              if (!isError) toast.warn('Code deleted');
            }}
          >
            Delete Code
          </Button>
        </div>
      </Paper>

      {selectedCode?.id && (
        <div style={{textAlign: 'left', whiteSpace: 'pre-wrap'}}>
          <p>
            <b>Name:</b> {selectedCode.name}
          </p>
          <p>
            <b>Desc:</b> {selectedCode.desc}
          </p>
          <p>
            <b>Code:</b>
            <br /> {selectedCode.code}
          </p>
        </div>
      )}

      <Modal
        className={styles.modal}
        open={codeModalOpen}
        onClose={() => {
          setCodeModalOpen(false);
        }}
      >
        <EditCodeModal
          getCodeAsync={getCodeAsync}
          handleClose={() => {
            setCodeModalOpen(false);
          }}
          selectedCode={codeModalOpen === 'edit' && selectedCode}
        />
      </Modal>
    </div>
  );
}
