import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import {
  AddCircleOutlined,
  DeleteForeverOutlined,
  SaveOutlined,
  RefreshOutlined,
} from '@material-ui/icons';
import {
  addTemplateImage,
  updatePageRegions,
  deleteTemplateImage,
  getImageById,
} from '../../helpers/requester';
import {
  getTemplate,
  selectImage,
  selectRegion,
  loadTemplatesForCountry,
  setRegionsChanged,
  setImageType,
  setHideInvalidImages,
} from '../../slices/templateSlice';

import {ConfirmModal} from '../modals/ConfirmModal/ConfirmModal';
import styles from './RegionsPanel.module.css';

const nullToUndefined = obj => {
  const res = Object.keys(obj).reduce((acc, key) => {
    acc[key] = obj[key] === null ? undefined : obj[key];

    return acc;
  }, {});

  return res;
};

export function RegionsPanel({
  regions,
  images,
  addRegion,
  editRegion,
  removeRegion,
  setGridView,
}) {
  const {
    selectedCountry,
    selectedPage,
    selectedTemplate,
    selectedRegion,
    selectedImageId,
    selectedImageType,
    dictionaries,
    regionsChanged,
    countries,
    hideInvalidImages,
  } = useSelector(getTemplate);
  const dispatch = useDispatch();
  const [deleteImageModalOpen, setDeleteImageModalOpen] = useState('');
  const [isFetchingImg, setIsFetchingImg] = useState(false);

  useEffect(() => {
    if (!images.find(({id}) => id === selectedImageId))
      dispatch(selectImage(images[0]?.id));
  }, [dispatch, images, selectedImageId]);

  useEffect(() => {
    dispatch(selectRegion(null));
  }, [dispatch, selectedPage]);

  const onFileChange = e => {
    const {files} = e.target;

    if (files.length) {
      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader();

        reader.onload = async () => {
          const result = reader.result.split(',');
          const {isError} = await addTemplateImage({
            pageId: selectedTemplate?.[selectedPage]?.id,
            imageb64: result[1],
          });

          if (!isError) {
            toast.success('Image added');
          }
        };

        reader.readAsDataURL(files[i]);
      }
    }

    selectedCountry && dispatch(loadTemplatesForCountry(selectedCountry));
  };

  const handleDeleteImage = async () => {
    const {isError} = await deleteTemplateImage(selectedImageId);

    if (!isError) {
      toast.warn('Image deleted');
      selectedCountry &&
        (await dispatch(loadTemplatesForCountry(selectedCountry)));
    }
  };

  const handleUpdateRegions = async () => {
    const {isError} = await updatePageRegions({
      oid: selectedTemplate?.[selectedPage]?.id,
      // eslint-disable-next-line no-shadow
      page: {
        regions: regions.map(({localId, ...region}) => {
          const regio = {...region, type: region.type.name};

          return nullToUndefined(regio);
        }),
      },
    });
    dispatch(setRegionsChanged(false));

    if (!isError) {
      selectedCountry && dispatch(loadTemplatesForCountry(selectedCountry));
    }

    toast.success('Regions updated');
  };

  const selectPrevImage = useCallback(() => {
    const currentIndex =
      images.findIndex(({id}) => id === selectedImageId) || 0;
    const imageId = images[currentIndex - 1]?.id;

    imageId && dispatch(selectImage(imageId));
  }, [dispatch, images, selectedImageId]);

  const selectNextImage = useCallback(() => {
    const currentIndex =
      images.findIndex(({id}) => id === selectedImageId) || 0;
    const imageId = images[currentIndex + 1]?.id;

    imageId && dispatch(selectImage(imageId));
  }, [dispatch, images, selectedImageId]);

  const refreshImage = useCallback(() => {
    if (selectedImageId) {
      dispatch(selectImage(''));
      setTimeout(() => dispatch(selectImage(selectedImageId)), 0);
    }
  }, [dispatch, selectedImageId]);

  useEffect(() => {
    const onKeyUp = event => {
      const keyName = event.key;

      switch (keyName) {
        case 'ArrowLeft':
        case 'ArrowUp':
          selectPrevImage();
          break;
        case 'ArrowRight':
        case 'ArrowDown':
          selectNextImage();
          break;

        default:
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => document.removeEventListener('keyup', onKeyUp);
  }, [images, selectNextImage, selectPrevImage, selectedImageId]);

  const getFileName = () =>
    `${countries.find(({id}) => id === selectedCountry)?.name}.${
      selectedTemplate?.name
    }.${selectedPage}.${selectedImageId}`;

  const getRawImage = async () => {
    setIsFetchingImg(true);
    const {data} = await getImageById(selectedImageId, 'RAW');

    if (data) {
      const fr = new FileReader();
      fr.onload = () => {
        const link = document.createElement('a');
        link.href = fr.result;

        link.setAttribute('download', `${getFileName()}.jpg`);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
        setIsFetchingImg(false);
      };

      fr.readAsDataURL(data);
    } else {
      setIsFetchingImg(false);
    }
  };

  return (
    <div className={styles.selectionWrapper}>
      <div className={styles.buttonGroup}>
        <div className={styles.buttonsWrapper}>
          <FormControl className='DropDown' fullWidth variant='outlined'>
            <InputLabel id='select-country-label'>Select region</InputLabel>
            <Select
              disabled={!selectedPage}
              labelId='select-country-label'
              label='Select region'
              value={selectedRegion?.id || selectedRegion?.localId || 'none'}
              onChange={e => {
                const {value} = e.target;
                const regionObj =
                  regions.find(
                    ({id, localId}) => id === value || localId === value
                  ) || null;

                dispatch(selectRegion(regionObj));
              }}
            >
              <MenuItem key='none' value='none'>
                None
              </MenuItem>
              {regions.map(({id, localId, type}) => (
                <MenuItem key={id || localId} value={id || localId}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            title='Add region'
            disabled={!selectedPage}
            onClick={addRegion}
            variant='outlined'
          >
            <AddCircleOutlined />
          </Button>
          <Button
            title='Delete region'
            disabled={!selectedPage || !selectedRegion}
            onClick={removeRegion}
            variant='outlined'
          >
            <DeleteForeverOutlined />
          </Button>
          <Button
            title='Save all regions'
            disabled={!regionsChanged}
            onClick={handleUpdateRegions}
            variant='outlined'
          >
            <SaveOutlined />
          </Button>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            disabled={!selectedPage || !selectedRegion}
            onClick={editRegion}
            variant='outlined'
          >
            Change region attrs
          </Button>
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <div className={styles.flexCol}>
          <div className={styles.buttonsWrapper}>
            <FormControl
              className='DropDown image'
              fullWidth
              variant='outlined'
            >
              <InputLabel id='select-country-label'>Select Image</InputLabel>
              <Select
                disabled={!selectedPage}
                labelId='select-country-label'
                label='Select image'
                value={selectedImageId}
                onChange={e => {
                  const {value} = e.target;
                  dispatch(selectImage(value));
                }}
              >
                {images.map(({id}) => (
                  <MenuItem key={id} value={id}>
                    {id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.buttonsWrapper}>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  onChange={e => {
                    setGridView(e.target.checked);
                  }}
                  defaultChecked={false}
                />
              }
              label='Grid view'
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={hideInvalidImages}
                  onChange={e => {
                    dispatch(setHideInvalidImages(e.target.checked));
                  }}
                  name='hideInvalidImages'
                  color='primary'
                />
              }
              label='Hide invalid images'
            />
          </div>
        </div>

        <div className={styles.flexCol}>
          <div className={styles.buttonsWrapper}>
            <Button
              disabled={!selectedTemplate?.[selectedPage]?.id}
              style={{padding: 0}}
              variant='outlined'
            >
              <label
                title='Add image'
                style={{
                  padding: '6px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                htmlFor='fileInput'
              >
                <AddCircleOutlined />
                <input
                  style={{display: 'none'}}
                  multiple
                  id='fileInput'
                  disabled={!selectedTemplate?.[selectedPage]?.id}
                  onChange={onFileChange}
                  type='file'
                  value=''
                />
              </label>
            </Button>

            <Button
              title='Delete image'
              disabled={!selectedImageId}
              variant='outlined'
              onClick={() => {
                setDeleteImageModalOpen(true);
              }}
            >
              <DeleteForeverOutlined />
            </Button>

            <FormControl className='DropDown' fullWidth variant='outlined'>
              <InputLabel id='select-country-label'>
                Select image type
              </InputLabel>
              <Select
                labelId='select-country-label'
                label='Select image type'
                value={selectedImageType}
                onChange={e => {
                  dispatch(setImageType(e.target.value));
                }}
                style={{minWidth: '200px'}}
              >
                {dictionaries?.imgtype?.map(value => (
                  <MenuItem key={value[1]} value={value[1]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.buttonsWrapper}>
            <Button
              title='Prev image'
              disabled={!images?.length}
              onClick={selectPrevImage}
              variant='outlined'
            >
              {'<<'}
            </Button>

            <Button
              title='Reload image'
              disabled={!images?.length}
              onClick={refreshImage}
              variant='outlined'
            >
              <RefreshOutlined />
            </Button>

            <Button
              title='Next image'
              disabled={!images?.length}
              onClick={selectNextImage}
              variant='outlined'
            >
              {'>>'}
            </Button>

            <Button
              disabled={!selectedImageId}
              onClick={getRawImage}
              variant='outlined'
              style={{margin: 'auto 0'}}
            >
              {isFetchingImg ? <CircularProgress /> : 'GET RAW IMAGE'}
            </Button>
          </div>
        </div>
      </div>

      <ConfirmModal
        open={deleteImageModalOpen}
        message='Are you sure you want to delete Image?'
        handleClose={() => {
          setDeleteImageModalOpen(false);
        }}
        handleConfirm={handleDeleteImage}
      />
    </div>
  );
}
